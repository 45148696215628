/* istanbul ignore file */
import React, { FC, useState, useCallback, useMemo, useEffect } from "react";
import LastenOverzichtTable from "../lastenoverzicht-table/lastenoverzicht-table";
import { Card, useRequestInit } from "adviesbox-shared";
import { ProductOverzicht } from "../productoverzicht/product-overzicht";
import { Formik, FormikProps } from "formik";
import LastenOverzichtChart from "../lastenoverzicht-chart/lastenoverzicht-chart";
import LastenOverzichtCard from "../lastenoverzicht-card/lastenoverzicht-card";
import { Lasten, LastenOverzicht } from "../../../.generated/rapportage/rapportagetypes";
import "./snel-inzicht-drawer.scss";
import { useFetchData } from "../../../shared/components/dossier/dossier-wijzig-adviseur-modal/usefetchdata";
import { CardLoadingSpinner } from "../scenarios/card-loading-spinner";
import { useRefreshTrigger } from "../infra/use-refresh-trigger";

type KeuzeOpties = {
  MaandlastenoverzichtRadioButtons: string;
};

interface SnelInzichtDrawerProps {
  values?: KeuzeOpties;
}

export const SnelInzichtDrawer: FC<SnelInzichtDrawerProps> = ({ values }) => {
  const [lasten, setLasten] = useState<Lasten>();
  const { refreshTrigger } = useRefreshTrigger();
  const { settings, params, requestInit } = useRequestInit<{
    vestiging: string;
    adviesdossier: string;
    voorstel: string;
  }>();

  const getLastenOverzichtUrl = useMemo(() => {
    const baseUrl = `${settings.rapportageOrigin}`;
    return params.voorstel
      ? `${baseUrl}/Voorstellen/${params.voorstel}/SnelInzicht/LastenOverzicht`
      : `${baseUrl}/Adviesdossiers/${params.adviesdossier}/SnelInzicht/LastenOverzicht`;
  }, [settings.rapportageOrigin, params.adviesdossier, params.voorstel]);

  const getRequestInit = useCallback(
    () => ({
      ...requestInit,
      method: "POST",
      url: getLastenOverzichtUrl
    }),
    [requestInit, getLastenOverzichtUrl]
  );

  const { data, loading, error, refetch } = useFetchData<LastenOverzicht>(getLastenOverzichtUrl, getRequestInit);
  useEffect(() => {
    if (refreshTrigger) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      refetch();
    }
  }, [refreshTrigger, refetch]);

  if (error) return <div>Error: {error}</div>;

  return (
    <Formik
      initialValues={
        values || {
          MaandlastenoverzichtRadioButtons: "tabel"
        }
      }
      onSubmit={/* istanbul ignore next */ () => {}}
    >
      {({ values }: FormikProps<any>) => (
        <div className="snel-inzicht">
          <div className="row">
            <Card title="Productoverzicht" className="col-12 col-md-3">
              <ProductOverzicht />
            </Card>
            <div className="col-12 col-md-9">
              <div className="row">
                <Card
                  title="Maandlastenoverzicht"
                  radioOptions={[
                    { label: "Tabel", value: "tabel" },
                    { label: "Grafiek", value: "grafiek" }
                  ]}
                  className="col-12"
                >
                  {loading && <CardLoadingSpinner />}
                  {values.MaandlastenoverzichtRadioButtons === "tabel" && <LastenOverzichtTable data={data} />}
                  {values.MaandlastenoverzichtRadioButtons === "grafiek" && (
                    <LastenOverzichtChart
                      onHoverCallback={lasten => setLasten(lasten)}
                      onClickCallback={lasten => setLasten(lasten)}
                      data={data}
                    />
                  )}
                </Card>
                {values.MaandlastenoverzichtRadioButtons === "grafiek" && lasten && (
                  <Card title={`Jaar ${lasten.jaar}`} className="col-12">
                    <LastenOverzichtCard lasten={lasten} />
                  </Card>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </Formik>
  );
};
