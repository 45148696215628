/* istanbul ignore file */
// TODO: Fix coverage. Lokaal gaat het wel goed. Pipeline 0% tot 50% coverage.

import React, { ReactElement } from "react";
import { Formik, Form } from "formik";
import { LabeledCurrencyInput } from "adviesbox-shared";
import { Lasten } from "../../../.generated/rapportage/rapportagetypes";

type LastenOverzichtCardProps = {
  lasten: Lasten;
};

export type LastenOverzichtValues = {
  rente: number | null;
  aflossing: number | null;
  totaleBruto: number | null;
  fiscaal: number | null;
  kredietlast: number | null;
  belastingBox3: number | null;
  totaleNetto: number | null;
  restantSchuld: number | null;
  waardeOpbouw: number | null;
  toelichting: string | null;
};

const LastenOverzichtCard = ({ lasten }: LastenOverzichtCardProps): ReactElement => {
  const mapLastenToOverzicht = (lasten: Lasten): LastenOverzichtValues => {
    return {
      rente: lasten.rente,
      aflossing: lasten.hypotheekAflossing ?? 0 / 12,
      totaleBruto: lasten.brutoLast,
      fiscaal: lasten.fiscaalVoordeel,
      kredietlast: lasten.kredietlast,
      belastingBox3: lasten.vrh,
      totaleNetto: lasten.nettoLast,
      restantSchuld: lasten.somHypotheken,
      waardeOpbouw: lasten.waardeopbouw,
      toelichting: "n.v.t."
    };
  };

  return (
    <Formik
      enableReinitialize
      initialValues={mapLastenToOverzicht(lasten)}
      onSubmit={/* istanbul ignore next */ _values => {}}
    >
      {({ values }) => {
        return (
          <Form>
            <div className="row">
              <div className="col-6">
                <h3 className="px-3 py-2">Maandlasten</h3>
                <LabeledCurrencyInput name="rente" caption="Rente" readonly />
                { values.aflossing !== 0 && <LabeledCurrencyInput name="aflossing" caption="Aflossing" readonly /> }
                { values.kredietlast !== 0 && <LabeledCurrencyInput name="kredietlast" caption="Kredietlast" readonly /> }
                <LabeledCurrencyInput name="totaleBruto" caption="Totale bruto maandlast" readonly fontWeight="bold" />
                <LabeledCurrencyInput name="fiscaal" caption="Fiscaal voordeel" readonly />
                { values.belastingBox3 !== 0 && <LabeledCurrencyInput name="belastingBox3" caption="Belasting box 3" readonly /> }
                <LabeledCurrencyInput name="totaleNetto" caption="Totale netto maandlast" readonly fontWeight="bold" />
              </div>
              <div className="col-6">
                <h3 className="px-3 py-2">Schuld en waardeopbouw</h3>
                <LabeledCurrencyInput name="restantSchuld" caption="Restant schuld" readonly />
                { values.waardeOpbouw !== 0 && <LabeledCurrencyInput name="waardeOpbouw" caption="Waardeopbouw" readonly /> }

                {/* <h3 className="px-3 py-4">Toelichting</h3>
            <LabeledTextInput name="toelichting" caption="Toelichting" readonly /> */}
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default LastenOverzichtCard;
