import {
  AuthContext,
  ErrorPage,
  Icon,
  LoadingSpinner,
  Notificaties,
  NotificatiesContext,
  PageLoading,
  SupportTicketModalAjax,
  SupportRemoteAssistanceModal,
  useRequestInit,
  HomeButton,
  SupportMenu
} from "adviesbox-shared";
import classnames from "classnames";
import React, { ReactElement, useContext, useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { useParams } from "react-router-dom";
import useAbortableFetch from "use-abortable-fetch";
import { default as AdviseurNaam } from "../../../auth/AdviseurNaam";
import { AppDataContext } from "../../../navigation/appdata-context";
import { OrganisatiesOutput, VestigingenOutput } from "../../../.generated/licenties/licentiestypes";
import UserDetailsContext from "../../user-details/user-details-context";
import { InvalidAdviesboxResultErrorPage } from "../fetch-error-page/fetch-error-page";
import logo from "./../../../assets/new-collapsed-brand-logo.svg";
import { EnvironmentSwitch } from "./environment-switch";
import classes from "./TopNavbar.module.scss";

type TopNavBarProps = {
  home?: boolean;
};

const SeRunningSpinner = (): ReactElement => {
  const appDataContext = useContext(AppDataContext);

  return <><div className="dropdown">
    <div className={classnames("nav-link btn btn-link", classes.switch_environment)}>
      <span>{appDataContext.seRunning ? <LoadingSpinner size="S" disablePadding /> : ""}</span>
    </div>
  </div></>;
}

const NotificatiesMenu = (): ReactElement => {
  const notificatiesContext = useContext(NotificatiesContext);
  const [newEntryAlertNotificaties, setNewEntryAlertNotificaties] = useState<boolean>(false);

  useEffect(() => {
    setNewEntryAlertNotificaties(
      notificatiesContext.notificaties?.find(entry => entry.ongelezen && entry.bron !== "PostvakUit") ? true : false
    );
  }, [notificatiesContext.notificaties, setNewEntryAlertNotificaties]);

  return <Dropdown className="navigation-bar__item">
    <Dropdown.Toggle variant="link" id="dropdown-basic-notificaties" className="nav-link">
      {notificatiesContext.hasNotificatiesOpgehaald ? (
        <span
          data-testid="notificaties-toggle-button"
          onClick={
            () => {
              notificatiesContext.forceReload();
            }
          }
        >
          <Icon name="notifications" />
          { newEntryAlertNotificaties ? <div className={classes.nav_icon_new_entry_dot}></div> : null }
        </span>
      ) : (
        <LoadingSpinner size="S"></LoadingSpinner>
      )}
    </Dropdown.Toggle>
    <Dropdown.Menu className={classes.dropdown_menu}>
      <Notificaties />
    </Dropdown.Menu>
  </Dropdown>;
}

const AdviseurMenu = (): ReactElement => {
  const { signout, user } = useContext(AuthContext);
  const { userDetails } = useContext(UserDetailsContext);
  const { vestiging: vestigingId } = useParams<{ vestiging: string }>();
  const { params, settings, requestInit } = useRequestInit();

  const vestiging = useAbortableFetch<VestigingenOutput>(
    `${settings.licentiesOrigin}/Vestigingen/${vestigingId}`,
    requestInit
  );

  const organisatieId = userDetails.organisatieId ? userDetails.organisatieId : null;
  const organisatieUrl = organisatieId ? `${settings.licentiesOrigin}/Organisaties/${organisatieId}` : null;
  const organisatie = useAbortableFetch<OrganisatiesOutput>(organisatieUrl, requestInit);


  if (vestiging.error) {
    return <ErrorPage error={vestiging.error} />;
  }

  if (organisatie.error) {
    return <ErrorPage error={organisatie.error} />;
  }

  if (vestiging.loading || !vestiging.data || organisatie.loading || !organisatie.data) {
    return <PageLoading />;
  }

  const vestigingNaam =
    vestiging.data && typeof vestiging.data !== "string" && vestiging.data.isValid && params.vestiging
      ? vestiging.data.vestigingen?.[params.vestiging].naam
      : "";
  const organisatieNaam =
    organisatie.data && typeof organisatie.data !== "string" && organisatieId
      ? organisatie.data.organisaties?.[organisatieId].naam
      : "";

  return <Dropdown className="navigation-bar__item">
    <Dropdown.Toggle variant="link" id="dropdown-basic-adviseur" className="nav-link">
      <span className="adviseur-naam" data-testid="dropdown-adviseur">
        <span className="ml-auto">
          <AdviseurNaam />
        </span>
        <Icon name="chevron" alt="open" />
      </span>
    </Dropdown.Toggle>

    <Dropdown.Menu>
      <div className={classes.dropdown_item}>
        <span className={classnames("small-text", classes.small_text)}>
          {user && user.profile && user.profile.name}
        </span>
        <div className={classnames("small-text", classes.small_text)}>{vestigingNaam}</div>
        <span className={classnames("small-text", classes.small_text)}>{organisatieNaam}</span>
      </div>
      <Dropdown.Item onClick={signout} id="menu-item-uitloggen">
        Uitloggen
      </Dropdown.Item>
    </Dropdown.Menu>
  </Dropdown>
}

const TopNavbar = ({ home }: TopNavBarProps): ReactElement => {
  const { user } = useContext(AuthContext);
  const [showMeldingmaken, setShowMeldingMaken] = useState(false);
  const [showRemoteAssistance, setShowRemoteAssistance] = useState(false);
  const [showInvalidAdviesboxResultErrorPage, setShowInvalidAdviesboxResultErrorPage] = useState(false);
  const { params } = useRequestInit();

  if (showInvalidAdviesboxResultErrorPage) {
    return <InvalidAdviesboxResultErrorPage />;
  }

  if (!user) {
    return <ErrorPage error={new Error("user not logged in")} />;
  }

  return (
    <>
      <div className="navbar navbar-expand navbar-light">
        <ul className={`navbar-nav ${home ? "w-100 d-flex" : ""}`}>
          <li className="mr-auto">
            <HomeButton home={home} homepageLogoClass={classes.homepage_logo} logo={logo} />
          </li>
          {/* istanbul ignore next */ (process.env.NODE_ENV === "development" || process.env.NODE_ENV === "test") && (
            <li className="effect-container">
              <SeRunningSpinner />
            </li>
          )}
          {/* istanbul ignore next */ (process.env.NODE_ENV === "development" || process.env.NODE_ENV === "test") && (
            <li className="support-container">
              <EnvironmentSwitch />
            </li>
          )}
          <li className="support-container">
            <SupportMenu
              showMeldingMaken={() => setShowMeldingMaken(true)}
              showRemoteAssistance={() => setShowRemoteAssistance(true)}
              classes={{
                iconblack: classes.iconblack,
                iconpadding: classes.iconpadding
              }}
            />
          </li>
          <li className={classes.nav_icon}>
            <NotificatiesMenu />
          </li>
          <li className="nav-item adviseur-container">
            <AdviseurMenu />
          </li>
        </ul>
      </div>
      {/* istanbul ignore next */ showMeldingmaken && (
        <SupportTicketModalAjax
          adviesdossierId={params.adviesdossier ?? null}
          user={user}
          closeModal={() => setShowMeldingMaken(false)}
          showInvalidResultErrorPage={() => setShowInvalidAdviesboxResultErrorPage(true)}
          vestigingId={params.vestiging}
        />
      )}
      {/* istanbul ignore next */ showRemoteAssistance && (
        <SupportRemoteAssistanceModal closeModal={() => setShowRemoteAssistance(false)} />
      )}
    </>
  );
};

TopNavbar.displayName = "TopNavbar";

export default TopNavbar;
