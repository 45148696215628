/* istanbul ignore file */
import {
  hasValue,
  Icon,
  LabeledCurrencyInput,
  LabeledSelectInput,
  LabeledTextInput,
  ModalButton,
  Card
} from "adviesbox-shared";
import { useFormikContext } from "formik";
import React, { ReactElement } from "react";
import AanvraagNaarProductModal from "../aanvraag-naar-product-modal/aanvraag-naar-product-modal";
import { AanvraagState, ProductType } from "../infra/aanvraag-schema";
import { AanvraagButton } from "../aanvraag-button/aanvraag-button";
import { getAanvraagTextResources } from "../infra/aanvraag-resources";

export enum AanvraagProductType {
  Aov,
  Orv
}

export enum AanvraagType {
  Serviceprovider = "Serviceprovider",
  SoftwareKoppeling = "SoftwareKoppeling"
}

type AanvraagProductCardProps = {
  productType: AanvraagProductType;
  title: string;
  product: ProductType;
  name: string;
  index?: number | undefined;
  testId: string;
  isLoading: boolean;
};

export const AanvraagProduct = ({
  productType,
  title,
  product,
  index,
  name,
  testId,
  isLoading
}: AanvraagProductCardProps): ReactElement => {
  const formik = useFormikContext<AanvraagState>();
  const { values, setFieldValue } = formik;
  const aanwezigTekst =
    product.aanwezigInBemiddeling === true
      ?  `Ja, ${product.voorstel}, Versie ${product.versie}`
      : "Nee";

  return (
    <Card title={title} className="col-6">
      <LabeledTextInput caption="Partij" name={`${name}.partijnaam`} readonly />
      {(
        <>
          <LabeledTextInput caption="Product" name={`${name}.productnaam`} readonly />
          <LabeledCurrencyInput caption="Premie" name={`${name}.premie`} readonly fieldSize="no-size" decimalen={2} />
        </>
      )}
      <LabeledTextInput
        caption="Aanwezig in Aanvragen"
        name={`${name}.aanwezigInBemiddeling`}
        readonly
        appendChildren={<span>{aanwezigTekst}</span>}
      />

      {title === "Overlijdensrisicoverzekering" &&
        hasValue(index) &&
        values.orvProduct[index].verzekerde?.length !== 0 && (
          <LabeledTextInput
            caption={values.orvProduct[index].verzekerde?.length === 2 ? "Verzekerden" : "Verzekerde"}
            name={`${name}.verzekerde.naamVerzekerde`}
            readonly
            appendChildren={
              <div>
                {values.orvProduct[index].verzekerde?.map((verzekerde, index) => (
                  <div key={`naam-verzekerde-${index}`} id={`naam-verzekerde-${index}`}>
                    <span>{verzekerde.naamVerzekerde} </span>
                  </div>
                ))}
              </div>
            }
          />
        )}
      {product.aanwezigInBemiddeling && (
          <LabeledSelectInput
            name={`${name}.overschrijven`}
            caption="Nieuwe aanvraag of nieuwe versie"
            options={[
              {
                label: "Nieuwe aanvraag",
                value: "false"
              },
              {
                label: "Nieuwe versie",
                value: "true"
              }
            ]}
          />
        )}
      <LabeledTextInput
        caption="Aanvraag naar"
        name={`${name}.aanvraagNaar`}
        readonly
        appendChildren={
          <>
            <ModalButton parent={`${name}`} content={<Icon name="specificatie" alt="Aanvraag naar" />}>
              <AanvraagNaarProductModal
                product={product}
                onSave={/* istanbul ignore next */ (data: ProductType): void => setFieldValue(`${name}`, data)}
              />
            </ModalButton>
          </>
        }
      />
      <AanvraagButton 
        formik={formik}
        testId={testId}
        buttonTekst={!product.aanvraagVerzonden ? getAanvraagTextResources("productaanvragen") : getAanvraagTextResources("productOpnieuwAanvragen") }
        nieuweVersieAanvraag={false}
        productId={product.productId ?? ""} 
        isLoading={isLoading} />

      {product.aanvraagVerzonden && (<AanvraagButton 
        formik={formik}
        testId={`update-${testId}`}
        buttonTekst={getAanvraagTextResources("nieuweVersieProductAanvragen")}
        nieuweVersieAanvraag={true}
        productId={product.productId ?? ""} 
        isLoading={isLoading} />)}
    </Card>
  );
};
