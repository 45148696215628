import {
  Icon,
  LabeledCurrencyInput,
  LabeledJaarMaandInput,
  LabeledSelectInput,
  LabeledTextInput,
  ModalButton,
  Card
} from "adviesbox-shared";
import { useFormikContext } from "formik";
import React, { ReactElement } from "react";
import AanvraagNaarHypotheekModal from "../aanvraag-naar-hypotheek-modal/aanvraag-naar-hypotheek-modal";
import { aanvraagHypotheekType, AanvraagState } from "../infra/aanvraag-schema";
import { AanvraagButton } from "../aanvraag-button/aanvraag-button";
import { getAanvraagTextResources } from "../infra/aanvraag-resources";

type AanvraagHypotheekCardProps = {
  title: string;
  isLoading: boolean;
};

export const AanvraagHypotheek = ({ title, isLoading }: AanvraagHypotheekCardProps): ReactElement => {
  const formik = useFormikContext<AanvraagState>();
  const { values, setFieldValue } = formik;
  const hypotheek = values.hypotheek;

  if (hypotheek == null) {
    return <></>;
  }

  return (
    <Card title={title} className="col-6">
      <LabeledTextInput caption="Partij" name="hypotheek.partijnaam" readonly />
      <LabeledTextInput caption="Product" name="hypotheek.productnaam" readonly />
      <LabeledJaarMaandInput caption="Looptijd" name="hypotheek.looptijdMaanden" readonly />
      {hypotheek.leningdelen.map((_leningdeel, index) => {
        return (
          <div key={index} id={`leningdeel-${index}`}>
            <LabeledTextInput
              caption={`Leningdeel ${index + 1}`}
              name={`hypotheek.leningdelen[${index}].aflossingsvorm`}
              key={`leningdeel-${index}`}
              readonly
            />
            <LabeledCurrencyInput
              fieldSize="no-size"
              key={`hoofdsom-${index}`}
              caption="Hoofdsom"
              name={`hypotheek.leningdelen[${index}].hoofdsom`}
              decimalen={2}
              readonly
            />
          </div>
        );
      })}
      <LabeledTextInput
        readonly
        caption="Aanwezig in Aanvragen"
        name={`hypotheek.aanwezigInBemiddeling`}
        appendChildren={
          <span>
            {hypotheek.aanwezigInBemiddeling === true ? `Ja, ${hypotheek.voorstel}, Versie ${hypotheek.versie}` : "Nee"}
          </span>
        }
      />
      {/* (tijdelijk) vervangen door selectie van een enkel product
      <LabeledBevestigingInput caption="Meenemen naar Aanvragen" name={`hypotheek.meenemenNaarBemiddeling`} /> 
      */}
      {hypotheek.aanwezigInBemiddeling && (
        <LabeledSelectInput
          name="hypotheek.overschrijven"
          caption={"Nieuwe aanvraag of nieuwe versie"}
          datatest-id={`hypotheek-overschrijven-${hypotheek.productId}`}
          options={[
            {
              label: "Nieuwe aanvraag",
              value: "false"
            },
            {
              label: "Nieuwe versie",
              value: "true"
            }
          ]}
        />
      )}
      <LabeledTextInput
        caption="Aanvraag naar"
        name="hypotheek.aanvraagNaar"
        readonly
        appendChildren={
          <>
            <ModalButton parent="hypotheek" content={<Icon name="specificatie" alt="Aanvraag naar" />}>
              <AanvraagNaarHypotheekModal
                aanvraag={values}
                onSave={
                  /* istanbul ignore next */ (data: aanvraagHypotheekType): void => setFieldValue(`hypotheek`, data)
                }
              />
            </ModalButton>
          </>
        }
      />
      <AanvraagButton 
        formik={formik}
        testId={"hypotheekProduct-0"}
        buttonTekst={!hypotheek.aanvraagVerzonden ? getAanvraagTextResources("productaanvragen") : getAanvraagTextResources("productOpnieuwAanvragen") }
        nieuweVersieAanvraag={false}
        productId={values.aanvraagOpBasisVan} 
        isLoading={isLoading} />

      {hypotheek.aanvraagVerzonden && (<AanvraagButton
        formik={formik}
        testId={"update-hypotheekProduct-0"}
        buttonTekst={getAanvraagTextResources("nieuweVersieProductAanvragen")}
        nieuweVersieAanvraag={true}
        productId={values.aanvraagOpBasisVan} 
        isLoading={isLoading} />)}
    </Card>
  );
};
/* istanbul ignore else */ if (process.env.NODE_ENV !== "production")
  AanvraagHypotheek.displayName = "AanvraagHypotheek";
