import {
  HypotheekAutomatischeRentedaling,
  HypotheekExtraAflossing,
  HypotheekFiscaleRegeling,
  FiscaalRegimeOptions,
  FiscaleTyperingOptions,
  FiscaleVoortzettingOptions,
  HypotheekHuidigeSituatie as HypotheekHuidigeSituatieDlEntry,
  HypotheekHuidigeSituatieLeningdeel,
  HypotheekHypotheeklabel,
  HypotheekKapitaalopbouw,
  Hypotheekoptie,
  HypotheekoptiesIngLeningdeel,
  HypotheekoptiesIngPriceTool,
  HypotheekPand,
  HypotheekPremie,
  BetalingsTermijnType,
  HypotheekPremiedepot,
  PremiedepotUitgangspunt,
  HypotheekRenteaftrek,
  HypotheekRenteopslagMarktwaarde,
  HypotheekVerklaringInkomen,
  HypotheekVoorstel as HypotheekVoorstelDlEntry,
  HypotheekVoorstelLeningdeel,
  RentevariantOptions,
  Hypotheekvorm,
  AflossingsVormType,
  OpbouwBerekening,
  PercentageIndexering,
  PercentageScenario,
  BewonersOpties
} from "../../.generated/forms/formstypes";
import { mapAanvragers } from "../../producten-overzicht/infra/map-aanvragers";
import { partijOnafhankelijk } from "../../producten-overzicht/infra/product-constanten";
import {
  HypothekenKenmerken,
  isKenmerkError,
  KenmerkenError
} from "../../producten-overzicht/infra/product-kenmerken-types";
import {
  FiscalegegevensType,
  PremieDepotModalType,
  PremieGegevensType
} from "../../producten-overzicht/infra/producten-overzicht-types";
import { jaarMaandInMaanden } from "../../shared/generic-parts/jaar-maand/map-ui-2-dl";
import { mapKlantnaamUi2Dl } from "../../shared/generic-parts/klantnaam/map-ui-2-dl";
import { KlantnaamType } from "../../shared/generic-parts/klantnaam/schema";
import { Common } from "../../shared/types";
import { createMapToDl } from "../../shared/utils/create-map-to-dl";
import { hasValue } from "../../shared/utils/helpers";
import { VerklaringInkomenType } from "../hypotheek-vergelijken-modal/hypotheek-vergelijken-types";
import { SoortOpnamesUitkering } from "../opnames/opnames-types";
import {
  hypotheekOptiesIngPriceToolLeningdeelSchema,
  hypotheekOptiesIngPriceToolSchema,
  hypotheekSchema,
  hypothekenBaseSchema,
  kapitaalopbouwSchema,
  pandSchema
} from "./hypotheek-schema";
import {
  HypotheekFiscaleRegelingType,
  HypotheekType,
  HypotheekVormType,
  HypothekenState,
  LeningdeelgegevensType,
  scenarioCardInputType
} from "./hypotheek-types";
import { getKenmerkCodeForProduct } from "./hypotheek-utils";
import { FiscaleRegelingType } from "../../producten-overzicht/infra/producten-overzicht-schema";
import { mapLocalDateToString } from "adviesbox-shared";

const mapPandUi2Dl = createMapToDl(pandSchema).to<HypotheekPand>({
  pandId: v => v.pandId,
  marktwaardeRenteBedrag: v => v.marktwaardeRenteBedrag,
  bevoorschottingspercentage: v => v.bevoorschottingspercentage,
  gebruikPand: v => v.gebruikPand,
  marktwaardeLtvBedrag: v => v.marktwaardeLtv.bedrag,
  marktwaardeLtvBerekendBedrag: v => v.marktwaardeLtv.berekendBedrag,
  marktwaardeLtvOvernemen: v => v.marktwaardeLtv.berekenen,
  totaleHypotheekBedrag: v => v.totaleHypotheekBedrag,
  bewoners: v => BewonersOpties.Aanvrager1, // TODO CONTROLEREN!!
  energieKlasse: v => null, // TODO CONTROLEREN!!
  meerwerkEbvBedrag: v => null // TODO CONTROLEREN!!
});

function mapRentedalingPercentages(leningdeel: LeningdeelgegevensType): HypotheekRenteopslagMarktwaarde[] {
  const rentedalingPercentages = leningdeel.automatischeRentedalingModal.rentedalingPercentages?.map(
    (c): HypotheekRenteopslagMarktwaarde => {
      return {
        marktwaardeTmPercentage: c.marktwaardePercentageTotEnMet,
        renteopslagPercentage: c.renteopslagPercentage
      };
    }
  );
  return rentedalingPercentages;
}

const mapAutomatischeRentedaling = (
  leningdeelgegevens: LeningdeelgegevensType
): HypotheekAutomatischeRentedaling | null => {
  return leningdeelgegevens.automatischeRentedaling
    ? {
        rentescenario: leningdeelgegevens.automatischeRentedalingModal.renteScenario,
        opslagNaRentevastperiodePercentage:
          leningdeelgegevens.automatischeRentedalingModal.opslagAfslagNaRentevastperiode,
        aanpassingRente: leningdeelgegevens.automatischeRentedalingModal.aanpassingRente,
        renteopslagen: mapRentedalingPercentages(leningdeelgegevens),
        marktwaardeRenteBedrag: leningdeelgegevens.automatischeRentedalingModal.marktwaardeRenteBedrag
      }
    : null;
};

function mapHypotheekVormUi2Dl(hypotheekVorm: HypotheekVormType, renteBoxCode: number): Hypotheekvorm {
  return {
    code: hypotheekVorm.code,
    omschrijving: hypotheekVorm.omschrijving,
    aflossingsvorm: hypotheekVorm.aflossingsvorm,
    starterslening: hypotheekVorm.isStartersLening,
    restschuldFinanciering: hypotheekVorm.isRestschuldLening,
    heeftLooptijdInMaanden: true, //ToDo NX: mail naar platform sturen
    renteboxCode: renteBoxCode
  };
}

export function mapPremieDepotUi2Dl(premieDepot: PremieDepotModalType | null): HypotheekPremiedepot | null {
  if (!premieDepot) return null;
  return {
    opnameAanvangsstorting: premieDepot.aanvangsstorting,
    opnameExtraStortingen: premieDepot.extraStortingen,
    opnameHogePremies: premieDepot.hogePremies,
    opnameLagePremies: premieDepot.lagePremies,
    premiedepotBedrag: premieDepot.bedrag,
    premiedepotDuurInMaanden: premieDepot.duur,
    vergoeding: premieDepot.vergoeding,
    uitgangspunt: premieDepot.uitgangspunt as PremiedepotUitgangspunt
  };
}

export function mapPremieUi2Dl(
  premie: PremieGegevensType | null,
  fiscaleRegeling: FiscaleRegelingType | null,
  kenmerken: HypothekenKenmerken
): HypotheekPremie | null {
  if (!premie) return null;
  return {
    aanvangsstorting:
      kenmerken.premie.aanvangstortingTonen &&
      (fiscaleRegeling?.fiscaleVoortzetting === null ||
        fiscaleRegeling?.fiscaleVoortzetting === FiscaleVoortzettingOptions.Geen ||
        kenmerken?.validaties?.HogeInlegFgvToegestaan === true)
        ? premie.aanvangExtraPremieStortingenBedrag
        : null,
    betalingsTermijn: premie.betalingstermijn as BetalingsTermijnType,
    einddatumStortingen: mapLocalDateToString(premie.einddatumPremieBetaling),
    einddatumStortingenHoogLaag: mapLocalDateToString(premie.hoogLaagEinddatum),
    hooglaagVerhouding: premie.hoogLaagVerhouding,
    premieBedrag: premie.spaarPremieLaag,
    premieTotaalBedragHoog: premie.totalePremieHoog,
    premieTotaalBedragLaag: premie.totalePremieLaag,
    premiedepot: kenmerken.premie.premiedepotTonen ? mapPremieDepotUi2Dl(premie.premiedepot) : null,
    premieduurHooglaagInMaanden: jaarMaandInMaanden(premie.hoogLaagLooptijd),
    premieduurInMaanden: jaarMaandInMaanden(premie.looptijd)
  };
}

export function mapFiscaleRegelingUi2Dl(
  fiscaleRegeling: HypotheekFiscaleRegelingType | null
): HypotheekFiscaleRegeling | null {
  if (!fiscaleRegeling) return null;
  return {
    productId:
      // in component <FiscaleRegeling/> wordt indien een product geselecteerd wordt,
      // het productId in fiscaleRegeling.fiscaleVoortzetting opgeslagen (formik state)
      fiscaleRegeling.fiscaleVoortzetting !== FiscaleVoortzettingOptions.EigenInvoer &&
      fiscaleRegeling.fiscaleVoortzetting !== FiscaleVoortzettingOptions.Geen &&
      fiscaleRegeling.fiscaleVoortzetting !== FiscaleVoortzettingOptions.VoortgezetProduct
        ? fiscaleRegeling.fiscaleVoortzetting
        : null,
    doelkapitaalBedrag: fiscaleRegeling.doelkapitaalBedrag,
    eerdereUitkeringenBedrag: fiscaleRegeling.eerdereUitkeringenBedrag,
    einddatum: mapLocalDateToString(fiscaleRegeling.einddatum),
    externeMaatschappijCode: fiscaleRegeling.externeMaatschappijCode,
    externeMaatschappijOmschrijving: fiscaleRegeling.externeMaatschappijOmschrijving,
    fiscaalRegime: fiscaleRegeling.fiscaalRegime as FiscaalRegimeOptions | null,
    fiscaleTypering: fiscaleRegeling.fiscaleTypering as FiscaleTyperingOptions | null,
    fiscaleVoortzetting: mapFiscaleVoortzetting(fiscaleRegeling.fiscaleVoortzetting),
    garantieverzekering: fiscaleRegeling.garantieverzekering,
    hoogstePremieOoitBedrag: fiscaleRegeling.hoogstePremieOoitBedrag,
    huidigeJaarPremieBedrag: fiscaleRegeling.huidigeJaarPremieBedrag,
    ingangsdatumBox1: mapLocalDateToString(fiscaleRegeling.ingangsdatumBox1),
    ingebrachteWaardeBedrag: fiscaleRegeling.ingebrachteWaardeBedrag,
    kapitaalopbouw: fiscaleRegeling.kapitaalopbouw,
    laagstePremieooitBedrag: fiscaleRegeling.laagstePremieooitBedrag,
    lijfrenteclausuleOrigineel: fiscaleRegeling.lijfrenteclausuleOrigineel,
    oorspronkelijkeIngangsdatum: mapLocalDateToString(fiscaleRegeling.oorspronkelijkeIngangsdatum),
    originelePolisnummer: fiscaleRegeling.originelePolisnummer,
    polisnummer: fiscaleRegeling.polisnummer,
    premieLopendJaarBedrag: fiscaleRegeling.premieLopendJaarBedrag
  };
}

function mapRenteaftrekkenUi2Dl(fiscalegegevens: FiscalegegevensType): HypotheekRenteaftrek[] {
  const rentes = fiscalegegevens.renteaftrekSpecificatie.renteAftrekken || [];
  const renteaftrekken = rentes.map(
    (c, index): HypotheekRenteaftrek => {
      return {
        volgnummer: index + 1,
        bedrag: c.bedrag,
        aanvangsdatum: mapLocalDateToString(c.aanvangsdatum) || "",
        einddatum: mapLocalDateToString(c.einddatum) || "",
        renteaftrekId: null
      };
    }
  );

  return renteaftrekken;
}

function mapFiscaleVoortzetting(
  fiscaleVoortzetting: string | FiscaleVoortzettingOptions | null
): FiscaleVoortzettingOptions | null {
  if (!fiscaleVoortzetting) {
    return null;
  }

  if (
    fiscaleVoortzetting !== FiscaleVoortzettingOptions.Geen &&
    fiscaleVoortzetting !== FiscaleVoortzettingOptions.EigenInvoer
  ) {
    return FiscaleVoortzettingOptions.VoortgezetProduct;
  }

  return FiscaleVoortzettingOptions[fiscaleVoortzetting];
}

function mapExtraAflossingenUi2Dl(hypotheek: HypotheekType): HypotheekExtraAflossing[] {
  const leningdeelgegevens: LeningdeelgegevensType = hypotheek.leningdeelgegevens;
  const extraAflossingen = leningdeelgegevens.extraAflossing.extraAflossingen;

  const extraAflossing = extraAflossingen.map(
    (c, index): HypotheekExtraAflossing => {
      return {
        volgnummer: index + 1,
        bedrag: c.bedrag || 0,
        datum: mapLocalDateToString(c.datum) || /* istanbul ignore next */ "",
        extraAflossingId: null,
        betalingstermijn: BetalingsTermijnType.Eenmalig,
        looptijdInMaanden: 0
      };
    }
  );

  // opnames zijn negatieve extra aflossingen, deze dus toevoegen aan extraAflossing
  const opnameBedrag = hypotheek.opnameBedrag;
  if (opnameBedrag) {
    extraAflossing.push({
      bedrag: -Math.abs(opnameBedrag),
      datum: hypotheek.product.ingangsdatum ? mapLocalDateToString(hypotheek.product.ingangsdatum) : "",
      volgnummer: extraAflossing.length ? extraAflossing.length + 1 : 1,
      extraAflossingId: null,
      betalingstermijn:
        hypotheek.opnameSoort === SoortOpnamesUitkering.Eenmalig
          ? BetalingsTermijnType.Geen
          : BetalingsTermijnType.Maand,
      looptijdInMaanden: hypotheek.opnameMaanden ?? 1 // 1 voor eenmalige uitkering
    });
  }

  return extraAflossing;
}

export function mapHypotheekOpties(voorstel: HypothekenState): Hypotheekoptie[] {
  const hypotheekOpties = voorstel.hypotheekOptie.hypotheekOpties;

  const hypotheekOptie = hypotheekOpties.map(
    (c): Hypotheekoptie => {
      return {
        code: c.code,
        omschrijving: c.omschrijving,
        toelichting: c.toelichting,
        geselecteerd: c.geselecteerd,
        rentekortingPercentage: c.rentekortingPercentage
      };
    }
  );
  return hypotheekOptie;
}

const mapHypotheekOptiesIngLeningDeel = createMapToDl(hypotheekOptiesIngPriceToolLeningdeelSchema)
  .with(hypotheekSchema)
  .to<HypotheekoptiesIngLeningdeel>({
    productnaam: (_, h) => h.product.productNaam,
    doorlopendLeningdeelMeenemen: v => v.meenemen,
    loyaliteitsbonusBedrag: v => v.loyaliteitsbonusBedrag,
    standaardRenteData: v => v.standaardRenteData,
    ltvData: v => v.ltvData,
    dagrenteData: v => v.dagrenteData,
    actieveBetaalrekeningData: v => v.actieveBetaalrekeningData,
    loyaliteitsbonusData: v => v.loyaliteitsbonusData,
    teBetalenRenteData: v => v.teBetalenRenteData,
    optimalisatiemelding: v => v.optimalisatieMelding
  });

const mapHypotheekOptiesIng = createMapToDl(hypotheekOptiesIngPriceToolSchema)
  .with(hypothekenBaseSchema)
  .to<HypotheekoptiesIngPriceTool>({
    actieveBetaalrekening: v => v.actieveBetaalRekening,
    dagrente: v => v.dagrente,
    loyaliteitsbonus: v => v.loyaliteitsbonus,
    correlationId: v => null,
    melding: v => null
  });

function mapRenteScenarioUi2Dl(
  scenarios: scenarioCardInputType[],
  rentePercentage: number | null,
  heeftAutomatischeRentedaling: boolean
): PercentageScenario {
  const indexeringen: PercentageIndexering[] = [];
  let valueTemp: number | null = null;
  let index = 0;
  let volgnummer = 1;
  // Vertaling van lijst met 30 jaar naar een lijst met indexeringen (begin maand - percentage)
  if (!heeftAutomatischeRentedaling) {
    scenarios.forEach(x => {
      if (x.percentage !== valueTemp) {
        indexeringen.push({
          volgnummer: volgnummer,
          ingangsmaand: index * 12 + 1,
          percentage: x.percentage
        });
        valueTemp = x.percentage;
        volgnummer++;
      }
      index++;
    });
  }

  return {
    waarde: rentePercentage,
    omschrijving: "Rente",
    indexeringen: indexeringen
  };
}

// zie task 59246 Velden met nummertje 2 zijn deprecated
const mapKapitaalOpbouw = createMapToDl(kapitaalopbouwSchema).to<HypotheekKapitaalopbouw>({
  doelkapitaal1Bedrag: v => v.doelkapitaal1Bedrag || v.doelkapitaal2Bedrag,
  doelkapitaal1Overnemen: v => v.doelkapitaal1Overnemen || v.doelkapitaal2Overnemen,
  doelkapitaal1Percentage: v => v.doelkapitaal1Percentage || v.doelkapitaal2Percentage,
  voorbeeldkapitaal1Bedrag: v => v.voorbeeldkapitaal1Bedrag || v.voorbeeldkapitaal2Bedrag,
  voorbeeldkapitaal1Percentage: v => v.voorbeeldkapitaal1Percentage || v.voorbeeldkapitaal2Percentage,
  doelkapitaal2Bedrag: () => null,
  doelkapitaal2Overnemen: () => null,
  doelkapitaal2Percentage: () => null,
  voorbeeldkapitaal2Bedrag: () => null,
  voorbeeldkapitaal2Percentage: () => null
});

export function mapHypotheekUi2Dl(
  hypotheek: HypotheekType,
  aanvrager1: KlantnaamType | null,
  aanvrager2: KlantnaamType | null,
  productKenmerken: HypothekenKenmerken,
  soort: "huidig"
): HypotheekHuidigeSituatieLeningdeel;
export function mapHypotheekUi2Dl(
  hypotheek: HypotheekType,
  aanvrager1: KlantnaamType | null,
  aanvrager2: KlantnaamType | null,
  productKenmerken: HypothekenKenmerken,
  soort: "voorstel"
): HypotheekVoorstelLeningdeel;
export function mapHypotheekUi2Dl(
  hypotheek: HypotheekType,
  aanvrager1: KlantnaamType | null,
  aanvrager2: KlantnaamType | null,
  productKenmerken: HypothekenKenmerken,
  soort: "voorstel" | "huidig"
): HypotheekHuidigeSituatieLeningdeel | HypotheekVoorstelLeningdeel {
  type HypotheekLeningdeelSharedMembers = Common<HypotheekHuidigeSituatieLeningdeel, HypotheekVoorstelLeningdeel>;

  const polis = {
    leningnummer: hypotheek.product.productNummer,
    maatschappijCode: hypotheek.partijCode,
    maatschappijOmschrijving: hypotheek.product.partijNaam,
    maatschappijCodeHdn: hypotheek.product.partijCodeSelectie,
    productcode: Number(hypotheek.productCode),
    aanvangsdatum: mapLocalDateToString(hypotheek.product.ingangsdatum),
    // einddatum: mapLocalDateToString(hypotheek.product.einddatum),
    looptijdInMaanden: jaarMaandInMaanden(hypotheek.product.looptijd)
  };
  const result: HypotheekLeningdeelSharedMembers = {
    ...polis,
    meenemen: false, // readonly
    aanvangsdatum: null,
    renteboxSoort: hypotheek.renteBoxSoort,
    renteaftrekken: mapRenteaftrekkenUi2Dl(hypotheek.fiscalegegevens),
    hypotheekvorm: mapHypotheekVormUi2Dl(hypotheek.hypotheekVorm, hypotheek.product.renteboxCode ?? 0),
    deelBox1Bedrag: hypotheek.fiscalegegevens.deelBox1Bedrag,

    volgnummer: hypotheek.volgnummer,
    uwBemiddeling: hypotheek.product.uwBemiddeling,
    klantIds: mapAanvragers(hypotheek.schuldenaars.schuldenaar, aanvrager1, aanvrager2),
    leningdeelBedrag: hypotheek.leningdeelgegevens.leningdeelHoofdsom
      ? hypotheek.leningdeelgegevens.leningdeelHoofdsom.bedrag
      : null,
    rentevastAantalMaanden:
      hypotheek.leningdeelgegevens.rentevastPeriodeJaar === null
        ? null
        : Number(hypotheek.leningdeelgegevens.rentevastPeriodeJaar) * 12 || 0,
    rentebedenktijdInMaanden:
      hypotheek.leningdeelgegevens.renteBedenktijdJaar === null
        ? null
        : hypotheek.leningdeelgegevens.renteBedenktijdJaar * 12,
    rentevastperiodeEinddatum: mapLocalDateToString(hypotheek.leningdeelgegevens.einddatum),
    renteScenario: mapRenteScenarioUi2Dl(
      hypotheek.leningdeelgegevens.renteScenarioModal.renteScenario,
      hypotheek.leningdeelgegevens.rentePercentage && hypotheek.leningdeelgegevens.rentePercentage.berekenen
        ? hypotheek.leningdeelgegevens.rentePercentage.berekendBedrag
        : hypotheek.leningdeelgegevens.rentePercentage?.bedrag ?? null,
      hypotheek.leningdeelgegevens.automatischeRentedaling
    ),
    aflossingBedrag: null,
    automatischeRentedaling: hypotheek.leningdeelgegevens.automatischeRentedaling
      ? mapAutomatischeRentedaling(hypotheek.leningdeelgegevens)
      : null,
    extraAflossingen: mapExtraAflossingenUi2Dl(hypotheek),
    leningdeelId: hypotheek.leningdeelgegevens.leningDeelId,

    rentevariant: hypotheek.leningdeelgegevens.renteVariant as RentevariantOptions,
    opgaveDatum: soort === "voorstel" ? null : mapLocalDateToString(hypotheek.leningdeelgegevens.datumOpgave),
    consumptiefBedrag: hypotheek.fiscalegegevens.deelBox3Bedrag ? hypotheek.fiscalegegevens.consumptiefBedrag : null
  };

  if (soort === "huidig") {
    const resultHuidig: HypotheekHuidigeSituatieLeningdeel = {
      ...result,
      aanvangsdatum: mapLocalDateToString(hypotheek.product.ingangsdatum) || "",
      pandId: hypotheek.hypotheekProductDetails ? hypotheek.hypotheekProductDetails.hypotheekOpWoning : "",
      wijzigingenInDoorlopendeProductenOvernemen: hypotheek.product.wijzigingenInDoorlopendProductOvernemen ?? true,
      rangorde: hypotheek.hypotheekProductDetails ? hypotheek.hypotheekProductDetails.rangorde : null,
      oorspronkelijkeHoofdsomBedrag: hypotheek.leningdeelgegevens.oorspronkelijkeHoofdsom,
      garantie: hypotheek.leningdeelgegevens.garantie,
      restantHoofdsomOvernemen: hypotheek.leningdeelgegevens.leningdeelHoofdsom
        ? hypotheek.leningdeelgegevens.leningdeelHoofdsom.berekenen
        : null,
      opgaveDatum: mapLocalDateToString(hypotheek.leningdeelgegevens.datumOpgave),
      rentevariant: result.rentevariant as RentevariantOptions,
      aflossingBedrag: hypotheek.leningdeelgegevens.periodiekeAflossing,
      meenemen: false // todo
    };
    return resultHuidig;
  } else {
    const resultaatVoostel: HypotheekVoorstelLeningdeel = {
      ...result,
      aanvangsdatum: mapLocalDateToString(hypotheek.product.ingangsdatum),
      verzekerdeKlantIds: mapAanvragers(hypotheek.verzekerde.verzekerde, aanvrager1, aanvrager2),
      verzekeringnemerKlantIds: mapAanvragers(hypotheek.verzekeringnemers.verzekeringnemer, aanvrager1, aanvrager2),
      doorlopend: hypotheek.product.doorlopend,
      rentevariant: result.rentevariant as RentevariantOptions,
      fiscaleRegeling:
        hypotheek.fiscaleRegeling && productKenmerken?.fiscaleRegeling.fiscaleRegelingTonen
          ? mapFiscaleRegelingUi2Dl(hypotheek.fiscaleRegeling)
          : null,
      kapitaalopbouw:
        [
          AflossingsVormType.Belegging,
          AflossingsVormType.Spaar,
          AflossingsVormType.Levensverzekering,
          AflossingsVormType.Hybride,
          AflossingsVormType.UnitLinked,
          AflossingsVormType.Spaarrekening
        ].includes(hypotheek.hypotheekVorm.aflossingsvorm) &&
        hypotheek.kapitaalopbouw &&
        productKenmerken?.kapitaalopbouw.kapitaalopbouwTonen
          ? mapKapitaalOpbouw(hypotheek.kapitaalopbouw)
          : null,
      overlijdensrisicoDekking: null,
      premie: productKenmerken?.premie.premiegegevensTonen
        ? mapPremieUi2Dl(hypotheek.premieGegevens, hypotheek.fiscaleRegeling, productKenmerken)
        : null,
      hypotheekoptiesIngPriceTool: hypotheek.hypotheekOptiesIngPriceToolLeningdeel
        ? mapHypotheekOptiesIngLeningDeel(hypotheek)(hypotheek.hypotheekOptiesIngPriceToolLeningdeel)
        : null
    };
    return resultaatVoostel;
  }
}

const mapVerklaringInkomenToDl = (
  verklaringInkomen: VerklaringInkomenType | null
): HypotheekVerklaringInkomen | null => {
  if (!verklaringInkomen) {
    return null;
  }
  return {
    accountantsverklaring: verklaringInkomen.accountantsverklaring,
    ibStukken: verklaringInkomen.ibStukken,
    perspectief: verklaringInkomen.perspectief,
    uwv: verklaringInkomen.uwv,
    werkgever: verklaringInkomen.werkgever
  };
};

function ui2dl(
  values: HypothekenState,
  situatie: "huidig",
  productKenmerken: Map<string, HypothekenKenmerken | KenmerkenError>
): HypotheekHuidigeSituatieDlEntry;
function ui2dl(
  values: HypothekenState,
  situatie: "voorstel",
  productKenmerken: Map<string, HypothekenKenmerken | KenmerkenError>
): HypotheekVoorstelDlEntry;
function ui2dl(
  values: HypothekenState,
  situatie: "huidig" | "voorstel",
  productKenmerken: Map<string, HypothekenKenmerken | KenmerkenError>
): HypotheekHuidigeSituatieDlEntry | HypotheekVoorstelDlEntry {
  type HypotheekLeningdeelSharedMembers = Common<HypotheekHuidigeSituatieDlEntry, HypotheekVoorstelDlEntry>;

  const result: HypotheekLeningdeelSharedMembers = {
    aanvrager1: mapKlantnaamUi2Dl(values.aanvrager1),
    aanvrager2: mapKlantnaamUi2Dl(values.aanvrager2),
    leningdelen: [],
    administratiekostenBedrag: null,
    maatschappijCode: values.producten.length > 0 ? values.producten.map(x => x.partijCode)[0] : null, // TODO: partijcode op hypotheken-niveau toevoegen???
    productnaam: null
  };

  if (situatie === "huidig") {
    const resultHuidig: HypotheekHuidigeSituatieDlEntry = {
      ...result,
      leningdelen: values.producten.map(product => {
        const kenmerken = productKenmerken.get(getKenmerkCodeForProduct(product));
        if (kenmerken === undefined || isKenmerkError(kenmerken)) {
          throw Error("Productkenmerken om het product op te kunnen slaan zijn niet aanwezig.");
        }
        return mapHypotheekUi2Dl(product, values.aanvrager1, values.aanvrager2, kenmerken, "huidig");
      }),
      panden: values.panden.map(mapPandUi2Dl),
      administratiekostenBedrag: null,
      maatschappijCode: null,
      productnaam: null,
      hypotheekAanwezig: false,
      oorspronkelijkHypotheekbedragIngevuld: values.benodigdHypotheekbedragIngevuld
    };
    return resultHuidig;
  } else {
    const voorstelProduct = values.producten.find(
      c =>
        (c.product.doorlopend === false ||
          c.partijCode !== partijOnafhankelijk ||
          c.product.partijCodeSelectie === partijOnafhankelijk) && // Bij een omzetting kunnen Partijcode en PartijCodeSelectie nooit beiden op "XX" staan
        c.hypotheekVorm.isStartersLening === false
    );

    if (voorstelProduct && !hasValue(voorstelProduct.labelCode)) {
      throw new Error("Geen labelCode aanwezig");
    }

    const resultVoorstel: HypotheekVoorstelDlEntry = {
      ...result,
      gewensteHypotheekBedrag: null,
      fiscaleVoortzettingKeuzes: null,
      leningdelen: values.producten.map(product => {
        const kenmerken = productKenmerken.get(getKenmerkCodeForProduct(product));
        if (kenmerken === undefined || isKenmerkError(kenmerken)) {
          throw Error("Productkenmerken om het product op te kunnen slaan zijn niet aanwezig.");
        }
        return mapHypotheekUi2Dl(product, values.aanvrager1, values.aanvrager2, kenmerken, "voorstel");
      }),
      pand: values.panden && values.panden.length > 0 ? mapPandUi2Dl(values.panden[0]) : null,
      eigenwoningschuldBedrag: values.eigenwoningschuldBedrag,
      hypotheeklabel: {
        code: values.hypotheeklabel?.labelCode ?? (hasValue(voorstelProduct) ? voorstelProduct.labelCode : 0),
        omschrijving: values.hypotheeklabel?.labelNaam ?? (hasValue(voorstelProduct) ? voorstelProduct.labelNaam : ""),
        hypotheekopties: hasValue(voorstelProduct) ? mapHypotheekOpties(values) : null,
        hypotheekoptiesIngPriceTool:
          hasValue(voorstelProduct) && values.hypotheekOptiesIngPriceTool
            ? mapHypotheekOptiesIng(values)(values.hypotheekOptiesIngPriceTool)
            : null,
        maatschappijCode:
          values.hypotheeklabel?.partijCode ?? (hasValue(voorstelProduct) ? voorstelProduct.partijCode : ""),
        maatschappijOmschrijving: null
      } as HypotheekHypotheeklabel,
      kredietenBox1Bedrag: null,
      nhg: values.nhg,
      productnaam: null,
      soortFinanciering: null,
      totaleHypotheekBedrag: null,
      aanvangsdatum: mapLocalDateToString(values.aanvangsdatum),
      verklaringInkomen: mapVerklaringInkomenToDl(values.voorwaardenSelectie?.verklaringInkomen ?? null),
      overbruggingBedrag: null
    };

    return resultVoorstel;
  }
}

const mapOpbouwProductUi2Dl = createMapToDl(hypotheekSchema)
  .with<{ aanvrager1: KlantnaamType | null; aanvrager2: KlantnaamType | null; productKenmerken: HypothekenKenmerken }>()
  .to<HypotheekVoorstelLeningdeel>({
    premie: (hypotheek, context) =>
      mapPremieUi2Dl(hypotheek.premieGegevens, hypotheek.fiscaleRegeling, context.productKenmerken),
    volgnummer: hypotheek => hypotheek.volgnummer,
    aflossingBedrag: hypotheek => hypotheek.leningdeelgegevens.periodiekeAflossing,
    automatischeRentedaling: hypotheek => mapAutomatischeRentedaling(hypotheek.leningdeelgegevens),
    deelBox1Bedrag: hypotheek => hypotheek.fiscalegegevens.deelBox1Bedrag,
    extraAflossingen: hypotheek => mapExtraAflossingenUi2Dl(hypotheek),
    fiscaleRegeling: (hypotheek, context) =>
      context.productKenmerken.fiscaleRegeling?.fiscaleRegelingTonen
        ? mapFiscaleRegelingUi2Dl(hypotheek.fiscaleRegeling)
        : null,
    hypotheekoptiesIngPriceTool: hypotheek =>
      hypotheek.hypotheekOptiesIngPriceToolLeningdeel
        ? mapHypotheekOptiesIngLeningDeel(hypotheek)(hypotheek.hypotheekOptiesIngPriceToolLeningdeel)
        : null,
    hypotheekvorm: hypotheek => mapHypotheekVormUi2Dl(hypotheek.hypotheekVorm, hypotheek.product.renteboxCode ?? 0),
    kapitaalopbouw: (hypotheek, context) =>
      [
        AflossingsVormType.Belegging,
        AflossingsVormType.Spaar,
        AflossingsVormType.Levensverzekering,
        AflossingsVormType.Hybride,
        AflossingsVormType.UnitLinked,
        AflossingsVormType.Spaarrekening
      ].includes(hypotheek.hypotheekVorm.aflossingsvorm) && context.productKenmerken.kapitaalopbouw.kapitaalopbouwTonen
        ? hypotheek.kapitaalopbouw
        : null,
    leningdeelBedrag: hypotheek =>
      hypotheek.leningdeelgegevens.leningdeelHoofdsom ? hypotheek.leningdeelgegevens.leningdeelHoofdsom.bedrag : null,
    leningdeelId: hypotheek => hypotheek.leningdeelgegevens.leningDeelId,
    leningnummer: hypotheek => hypotheek.product.productNummer,
    looptijdInMaanden: hypotheek => jaarMaandInMaanden(hypotheek.product.looptijd),
    maatschappijCode: hypotheek => hypotheek.partijCode,
    maatschappijCodeHdn: hypotheek => hypotheek.product.partijCodeSelectie,
    maatschappijOmschrijving: hypotheek => hypotheek.product.omschrijving,
    overlijdensrisicoDekking: hypotheek => null,
    productcode: hypotheek => Number(hypotheek.productCode),
    renteScenario: hypotheek =>
      mapRenteScenarioUi2Dl(
        hypotheek.leningdeelgegevens.renteScenarioModal.renteScenario,
        hypotheek.leningdeelgegevens.rentePercentage?.bedrag ?? null,
        hypotheek.leningdeelgegevens.automatischeRentedaling
      ),
    renteaftrekken: hypotheek => mapRenteaftrekkenUi2Dl(hypotheek.fiscalegegevens),
    rentebedenktijdInMaanden: hypotheek =>
      hypotheek.leningdeelgegevens.renteBedenktijdJaar === null
        ? null
        : hypotheek.leningdeelgegevens.renteBedenktijdJaar * 12,
    rentevariant: hypotheek => hypotheek.leningdeelgegevens.renteVariant as RentevariantOptions,
    rentevastAantalMaanden: hypotheek =>
      hypotheek.leningdeelgegevens.rentevastPeriodeJaar === null
        ? null
        : Number(hypotheek.leningdeelgegevens.rentevastPeriodeJaar) * 12 || 0,
    rentevastperiodeEinddatum: hypotheek => mapLocalDateToString(hypotheek.leningdeelgegevens.einddatum),
    uwBemiddeling: hypotheek => hypotheek.product.uwBemiddeling,
    verzekerdeKlantIds: (hypotheek, w) => mapAanvragers(hypotheek.verzekerde.verzekerde, w.aanvrager1, w.aanvrager2),
    verzekeringnemerKlantIds: (hypotheek, w) =>
      mapAanvragers(hypotheek.verzekeringnemers.verzekeringnemer, w.aanvrager1, w.aanvrager2),
    aanvangsdatum: hypotheek => mapLocalDateToString(hypotheek.product.ingangsdatum),
    doorlopend: hypotheek => hypotheek.product.doorlopend,
    meenemen: hypotheek => hypotheek.product.meenemen,
    opgaveDatum: hypotheek => mapLocalDateToString(hypotheek.leningdeelgegevens.datumOpgave),
    renteboxSoort: () => null,
    consumptiefBedrag: () => null
  });

export const mapProductToInlegUi2Dl = createMapToDl(hypotheekSchema)
  .with<{ aanvrager1: KlantnaamType | null; aanvrager2: KlantnaamType | null; productKenmerken: HypothekenKenmerken }>()
  .to<OpbouwBerekening>({
    opbouwProduct: (v, w) =>
      mapOpbouwProductUi2Dl({
        aanvrager1: w.aanvrager1,
        aanvrager2: w.aanvrager2,
        productKenmerken: w.productKenmerken
      })(v)
  });

export const mapHypotheekVoorstelUiToDl = (productKenmerken: Map<string, HypothekenKenmerken | KenmerkenError>) => (
  values: HypothekenState
): HypotheekVoorstelDlEntry => {
  return ui2dl(values, "voorstel", productKenmerken);
};

export const mapHypotheekHuidigUiToDl = (productKenmerken: Map<string, HypothekenKenmerken | KenmerkenError>) => (
  values: HypothekenState
): HypotheekHuidigeSituatieDlEntry => {
  return ui2dl(values, "huidig", productKenmerken);
};
