import React from "react";
import { LineChart, bedragFormat } from "adviesbox-shared";
import { LastenOverzicht, Lasten } from "../../../.generated/rapportage/rapportagetypes";
import "./lastenoverzicht-chart.scss";

type LastenOverzichtChartProps = {
  onHoverCallback?: (jaarGegevens: Lasten) => void;
  onClickCallback?: (jaarGegevens: Lasten) => void;
  data: LastenOverzicht | null | undefined;
};

const LastenOverzichtChart: React.FC<LastenOverzichtChartProps> = ({
  onHoverCallback,
  onClickCallback,
  data
}: LastenOverzichtChartProps) => {
  return (
    <div className="p-2 lastenoverzicht-chart" data-testid="line-chart">
      {!data?.jaarGegevens?.length && <>Geen lastenoverzicht gegevens beschikbaar</>}
      <LineChart
        labels={data?.jaarGegevens?.map(c => c.jaar.toString()) ?? []}
        optionsObject={{
          maintainAspectRatio: false,
          parsing: {
            xAxisKey: "id",
            yAxisKey: "value"
          },
          scales: {
            x: {
              ticks: {
                maxRotation: 45,
                minRotation: 45
              }
            },
            y: {
              ticks: {
                callback: /* istanbul ignore next */ (val: any, _index: any) => {
                  return bedragFormat(val);
                }
              }
            }
          },
          plugins: {
            legend: {
              display: true,
              align: "start",
              labels: {
                boxPadding: 16,
                usePointStyle: true,
                boxHeight: 8,
                boxWidth: 8
              }
            },
            tooltip: {
              callbacks: {
                label: /* istanbul ignore next */ (tooltipItem: any) => {
                  return " " + bedragFormat(tooltipItem.dataset.data[tooltipItem.dataIndex].value, 0);
                }
              }
            }
          },
          onHover: /* istanbul ignore next */ (e: any, items: any) => {
            if (items.length && onHoverCallback) {
              const year = items[0].element.$context.raw.id;
              const jaarGegevens = data?.jaarGegevens?.find(c => c.jaar === year);
              if (jaarGegevens) onHoverCallback(jaarGegevens);
            }
          },
          onClick: /* istanbul ignore next */ (e: any, items: any) => {
            if (items.length && onClickCallback) {
              const year = items[0].element.$context.raw.id;
              const jaarGegevens = data?.jaarGegevens?.find(c => c.jaar === year);
              if (jaarGegevens) onClickCallback(jaarGegevens);
            }
          }
        }}
        datasets={[
          {
            label: "Bruto maandlasten",
            data: data
              ? data?.jaarGegevens?.map(c => {
                  return { id: c.jaar, value: c.brutoLast };
                })
              : [],
            backgroundColor: "#DF2935",
            borderColor: "#DF2935",
            borderWidth: 3,
            radius: 0
          },
          {
            label: "Netto maandlasten",
            backgroundColor: "#C2F970",
            borderColor: "#C2F970",
            data: data
              ? data.jaarGegevens?.map(c => {
                  return { id: c.jaar, value: c.nettoLast };
                })
              : [],
            borderWidth: 3,
            radius: 0
          }
        ]}
      />
    </div>
  );
};

export default LastenOverzichtChart;
