import { LocalDate } from "@js-joda/core";
import * as Yup from "yup";
import {
  AanpassingRenteOptions,
  Garantiesoort,
  DoorlopendLeningdeelMeenemenOptions,
  EnergieKlasseType,
  GebruikPandSoort,
  RentevariantOptions,
  Financieringsoort,
  FiscaalRegimeOptions,
  FiscaleTyperingOptions,
  KapitaalopbouwOptions
} from "../../.generated/forms/formstypes";
import { AflossingsVormType } from "../../.generated/producten/productentypes";
import { getProductenOverzichtTextResources } from "../../producten-overzicht/infra/producten-overzicht-resources";
import {
  fiscalegegevensSchema,
  fiscaleRegelingSchema,
  premieGegevensSchema,
  productSchema
} from "../../producten-overzicht/infra/producten-overzicht-schema";
import { SituatieSoort } from "../../producten-overzicht/infra/producten-overzicht-types";
import { jaarMaandInputSchema, JaarMaandInputType } from "../../shared/generic-parts/jaar-maand/schema";
import { klantnaamSchema } from "../../shared/generic-parts/klantnaam/schema";
import { AanvragerKeuze, berekenInputSchema } from "../../shared/types";
import { hasValue } from "../../shared/utils/helpers";
import { yupEnum, yupNullableEnum } from "../../shared/utils/yup-enum";
import { getHypotheekTextResources, getHypotheekVergelijkenTextResources } from "./hypotheek-resources";
import { scenarioCardInputType } from "./hypotheek-types";
import { getLeeftijdValidationText } from "./hypotheek-validations";
import {
  HypothekenKenmerken,
  KenmerkenError,
  isKenmerkError
} from "../../producten-overzicht/infra/product-kenmerken-types";
import { Return } from "../../shared/hooks/use-map";
import { getHuidigeProductKenmerken } from "./hypotheek-utils";
import { SoortOpnamesUitkering } from "../opnames/opnames-types";
import { bedragFormat, nullableLocalDateSchema } from "adviesbox-shared";

export const hypotheekFiscaleRegelingSchema = fiscaleRegelingSchema.shape({
  polisnummer: Yup.string().nullable()
});

export const hypotheekProductSchema = productSchema.shape({
  ingangsdatum: nullableLocalDateSchema.test("datum-check", "", function(value): true | Yup.ValidationError {
    const context = this.options.context as HypotheekSchemaContextType;
    if (!context.productKenmerken) return true;
    const huidigProductKenmerken = getHuidigeProductKenmerken(
      this.parent.partijCodeSelectie,
      this.parent.productNummer,
      context.productKenmerken
    );
    if (!huidigProductKenmerken || isKenmerkError(huidigProductKenmerken)) return true;
    // Als minimaleLeeftijdInMaanden niet is ingevuld, dan standaard waarde van 72 jaar (864 maanden) aanhouden
    const minimaleLeeftijdInMaanden = huidigProductKenmerken.validaties.aanvangsleeftijdInMaanden || 864;
    const minimaleLeeftijdInJaren = Math.ceil(minimaleLeeftijdInMaanden / 12);
    if (this.parent.betreftOpname) {
      const aowValidationError = getLeeftijdValidationText(
        value,
        context.geboorteDatumAanvrager1,
        context.geboorteDatumAanvrager2,
        minimaleLeeftijdInJaren
      );
      if (aowValidationError) {
        return this.createError({
          message: aowValidationError
        });
      }
    }
    return true;
  }),
  looptijd: jaarMaandInputSchema
    .test({
      test: function(val: JaarMaandInputType) {
        const context = this.options.context as HypotheekSchemaContextType & {
          minimaleLooptijdMaanden: number | null;
        };
        if (!val || !val.jaren || val.jaren > 50) {
          return this.createError({
            path: `${this.path}.jaren`,
            message: getHypotheekTextResources("ErrorProductLooptijd")
          });
        }

        if (val.jaren === 50 && val.maanden) {
          return this.createError({
            path: `${this.path}.maanden`,
            message: getHypotheekTextResources("ErrorProductLooptijd")
          });
        }

        if (context.minimaleLooptijdMaanden && val.jaren * 12 < context.minimaleLooptijdMaanden) {
          return this.createError({
            path: `${this.path}.jaren`,
            message: getHypotheekTextResources("ErrorProductLooptijdMinimaleOpnameDuurMaanden").replace(
              "{0}",
              context.minimaleLooptijdMaanden.toString()
            )
          });
        }

        return true;
      }
    })
    .default({ jaren: 30, maanden: null }),
  betreftOpname: Yup.boolean().default(false)
});

export type productDetails = {
  volgnummer: number;
  bedrag: number;
};
export type HypotheekSchemaContextType = {
  oorspronkelijkeHoofdsomTotaalError: boolean;
  leningdeelHoofdsomTotaalError: boolean;
  situatie: SituatieSoort;
  geboorteDatumAanvrager1: LocalDate | null;
  geboorteDatumAanvrager2: LocalDate | null;
  productKenmerken: Return<string, HypothekenKenmerken | KenmerkenError> | null;
};

export type ExtraAflossingeModalSchemaContextType = {
  productIngangsdatum: LocalDate | null;
  productEinddatum: LocalDate | null;
};

export const renteAftrekSchema = Yup.object({
  aanvangsdatum: nullableLocalDateSchema,
  einddatum: nullableLocalDateSchema,
  bedrag: Yup.number()
    .min(1, getHypotheekTextResources("ErrorRenteAftrekBedrag"))
    .nullable()
    .default(null)
});

export const specificatieRenteaftrekModalSchema = Yup.object().shape({
  renteAftrekken: Yup.array(renteAftrekSchema).default([])
});

export const aflosProductSchema = Yup.object({
  code: Yup.string()
    .nullable()
    .default("2")
    .required(getHypotheekTextResources("ErrorAflosvormSelectie")),
  renteBoxMaatschappijCode: Yup.string()
    .nullable()
    .default(null),
  productOmschrijving: Yup.string().default("Annuïteit"),
  aflosvorm: Yup.mixed<AflossingsVormType>()
    .oneOf([...Object.values(AflossingsVormType), null], getHypotheekTextResources("ErrorOnbekendeWaarde"))
    .default(AflossingsVormType.Annuïteit),
  renteboxCode: Yup.number()
    .nullable()
    .default(null)
});

export const aflosProductVoorstelSchema = aflosProductSchema.shape({
  code: Yup.string()
    .nullable()
    .default("3"),
  productOmschrijving: Yup.string().default("AnnuïteitenHypotheek")
});

export const maatschappijProductSchema = Yup.object({
  maatschappijCode: Yup.string(),
  maatschappijOmschrijving: Yup.string(),
  maatschappijLabelCode: Yup.string(),
  labelCode: Yup.number(),
  labelOmschrijving: Yup.string()
});

export const maatschappijProductHuidigSchema = maatschappijProductSchema.shape({
  maatschappijCode: Yup.string(),
  maatschappijOmschrijving: Yup.string(),
  maatschappijLabelCode: Yup.string(),
  labelCode: Yup.number()
});

export const maatschappijProductVoorstelSchema = maatschappijProductSchema.shape({
  maatschappijCode: Yup.string(),
  maatschappijOmschrijving: Yup.string(),
  maatschappijLabelCode: Yup.string(),
  labelCode: Yup.number(),
  labelOmschrijving: Yup.string()
});

export const extraAflossingenSchema = Yup.object({
  bedrag: Yup.number()
    .nullable()
    .default(null),
  datum: nullableLocalDateSchema.test("datum-check", "", function(): true | Yup.ValidationError {
    const context = this.options.context as ExtraAflossingeModalSchemaContextType;
    const valid =
      context.productIngangsdatum != null && context.productEinddatum != null && this.parent.datum != null
        ? this.parent.datum >= context.productIngangsdatum && this.parent.datum < context.productEinddatum
        : true;
    if (!valid) {
      return this.createError({
        message: getHypotheekTextResources("ErrorExtraAflossingDatum")
      });
    }
    return true;
  })
});

export const extraAflossingenModalSchema = Yup.object().shape({
  extraAflossingen: Yup.array(extraAflossingenSchema).default([])
});

export const rentedalingPercentagesSchema = Yup.object({
  marktwaardePercentageTotEnMet: Yup.number()
    .typeError(getHypotheekTextResources("MinMaxMarktwaardePercentage"))
    .min(0, getHypotheekTextResources("MinMaxMarktwaardePercentage"))
    .max(200, getHypotheekTextResources("MinMaxMarktwaardePercentage")),
  renteopslagPercentage: Yup.number()
    .min(0, getHypotheekTextResources("MaxPercentageRenteopslag"))
    .max(9.99, getHypotheekTextResources("MaxPercentageRenteopslag"))
    .default(0)
});

export const rentedalingObvMarktwaardePercentageModalSchema = Yup.object({
  aanpassingRente: Yup.mixed<AanpassingRenteOptions>().oneOf(
    [...Object.values(AanpassingRenteOptions), null],
    getHypotheekTextResources("ErrorOnbekendeWaarde")
  ),
  rentedalingPercentages: Yup.array(rentedalingPercentagesSchema).default([]),
  renteScenario: Yup.boolean(),
  opslagAfslagNaRentevastperiode: Yup.number()
    .min(-9.99, getHypotheekTextResources("MinMaxPercentageRenteopslag"))
    .max(9.99, getHypotheekTextResources("MinMaxPercentageRenteopslag"))
    .nullable(),
  /** Gehanteerde marktwaarde */
  marktwaardeRenteBedrag: Yup.number().nullable(),
  automatischeRentedalingEnabled: Yup.boolean().nullable()
}).default({
  aanpassingRente: AanpassingRenteOptions.DirectBijLagereLtv,
  rentedalingPercentages: [],
  renteScenario: false,
  opslagAfslagNaRentevastperiode: null,
  marktwaardeRenteBedrag: null
});

export const scenarioCardInputSchema = Yup.object({
  bedrag: Yup.number().nullable(),
  percentage: Yup.number()
    .nullable()
    .max(20, getHypotheekTextResources("ErrorRentePercentageMax"))
    .default(null)
});

export const renteScenarioModalSchema = Yup.object({
  vervolgjarenAutomatischInvullen: Yup.boolean().default(false),
  renteScenario: Yup.array(scenarioCardInputSchema).default(
    new Array<scenarioCardInputType>(30).fill(scenarioCardInputSchema.default())
  )
});

export const pandHypotheekBedragenSchema = Yup.object({
  pandId: Yup.string(),
  totaleHypotheekBedrag: Yup.number(),
  marktwaardeBedrag: Yup.number()
});

export const hypotheekOptiesSchema = Yup.object({
  code: Yup.number(),
  omschrijving: Yup.string().nullable(),
  toelichting: Yup.string().nullable(),
  geselecteerd: Yup.boolean(),
  rentekortingPercentage: Yup.number().nullable(),
  maatschappijCode: Yup.string().nullable(),
  hypotheekLabelCode: Yup.number().nullable(),
  default: Yup.bool().nullable(),
  verplicht: Yup.bool().nullable()
});

export const hypotheekOptieSchema = Yup.object().shape({
  hypotheekOpties: Yup.array(hypotheekOptiesSchema).default([])
});

const hypotheekoptiesIngRenteDataSchema = Yup.object({
  percentage: Yup.number()
    .nullable()
    .default(null),
  melding: Yup.string()
    .nullable()
    .default(null)
});

export const hypotheekOptiesIngPriceToolLeningdeelSchema = Yup.object({
  meenemen: Yup.mixed<DoorlopendLeningdeelMeenemenOptions>()
    .oneOf(
      [...Object.values(DoorlopendLeningdeelMeenemenOptions), null],
      getHypotheekTextResources("ErrorOnbekendeWaarde")
    )
    .nullable()
    .default(DoorlopendLeningdeelMeenemenOptions.EenOpEen),
  loyaliteitsbonusBedrag: Yup.number()
    .nullable()
    .default(null),
  standaardRenteData: hypotheekoptiesIngRenteDataSchema.nullable().default(null),
  ltvData: hypotheekoptiesIngRenteDataSchema.nullable().default(null),
  dagrenteData: hypotheekoptiesIngRenteDataSchema.nullable().default(null),
  actieveBetaalrekeningData: hypotheekoptiesIngRenteDataSchema.nullable().default(null),
  loyaliteitsbonusData: hypotheekoptiesIngRenteDataSchema.nullable().default(null),
  teBetalenRenteData: hypotheekoptiesIngRenteDataSchema.nullable().default(null),
  optimalisatieMelding: Yup.string()
    .nullable()
    .default(null),
  errorMelding: Yup.string()
    .nullable()
    .default(null)
});

export const hypotheekOptiesIngPriceToolSchema = Yup.object({
  dagrente: Yup.boolean().default(false),
  actieveBetaalRekening: Yup.boolean().default(true),
  loyaliteitsbonus: Yup.boolean().default(false)
});

const hypotheekProductDetailsSchemaYup = Yup.object({
  hypotheekOpWoning: Yup.string().default(""),
  rangorde: Yup.number().default(1)
});

export const hypotheekProductDetailsSchema = hypotheekProductDetailsSchemaYup.default(
  hypotheekProductDetailsSchemaYup.default()
);

const schuldenaarsSchemaYup = Yup.object({
  schuldenaar: Yup.mixed<AanvragerKeuze>()
    .oneOf(Object.values(AanvragerKeuze), getProductenOverzichtTextResources("ErrorOnbekendeWaarde"))
    .default(AanvragerKeuze.Aanvrager1)
});

export const schuldenaarsSchema = schuldenaarsSchemaYup;

const verzekerdenSchemaYup = Yup.object({
  verzekerde: Yup.mixed<AanvragerKeuze>()
    .oneOf(Object.values(AanvragerKeuze), getProductenOverzichtTextResources("ErrorOnbekendeWaarde"))
    .default(AanvragerKeuze.Aanvrager1)
});

export const verzekerdenSchema = verzekerdenSchemaYup;

const verzekeringnemersSchemaYup = Yup.object({
  verzekeringnemer: Yup.mixed<AanvragerKeuze>()
    .oneOf(Object.values(AanvragerKeuze), getProductenOverzichtTextResources("ErrorOnbekendeWaarde"))
    .default(AanvragerKeuze.Aanvrager1)
});

export const verzekeringnemersSchema = verzekeringnemersSchemaYup;

const kapitaalopbouwSchemaYup = Yup.object({
  doelkapitaal1Bedrag: Yup.number()
    .min(1, getHypotheekTextResources("ErrorDoelkapitaalBedragMin"))
    .max(99999999, getHypotheekTextResources("ErrorDoelkapitaalBedragMax"))
    .nullable()
    .default(null),
  doelkapitaal2Bedrag: Yup.number()
    .min(1, getHypotheekTextResources("ErrorDoelkapitaalBedragMin"))
    .max(99999999, getHypotheekTextResources("ErrorDoelkapitaalBedragMax"))
    .nullable()
    .default(null),
  doelkapitaal1Percentage: Yup.number()
    .min(0, getHypotheekTextResources("ErrorDoelKapitaalPercentageMin"))
    .max(20, getHypotheekTextResources("ErrorDoelKapitaalPercentageMax"))
    .nullable()
    .default(null),
  doelkapitaal2Percentage: Yup.number()
    .min(0, getHypotheekTextResources("ErrorDoelKapitaalPercentageMin"))
    .max(20, getHypotheekTextResources("ErrorDoelKapitaalPercentageMax"))
    .nullable()
    .default(null),
  doelkapitaal1Overnemen: Yup.boolean()
    .default(false)
    .nullable(),
  doelkapitaal2Overnemen: Yup.boolean()
    .default(false)
    .nullable(),
  voorbeeldkapitaal1Bedrag: Yup.number()
    .min(1, getHypotheekTextResources("ErrorVoorbeeldKapitaalBedragMin"))
    .max(99999999, getHypotheekTextResources("ErrorVoorbeeldKapitaalBedragMax"))
    .nullable()
    .default(null),
  voorbeeldkapitaal2Bedrag: Yup.number()
    .min(1, getHypotheekTextResources("ErrorVoorbeeldKapitaalBedragMin"))
    .max(99999999, getHypotheekTextResources("ErrorVoorbeeldKapitaalBedragMax"))
    .nullable()
    .default(null),
  voorbeeldkapitaal1Percentage: Yup.number()
    .min(0, getHypotheekTextResources("ErrorVoorbeeldKapitaalPercentageMin"))
    .max(20, getHypotheekTextResources("ErrorVoorbeeldKapitaalPercentageMax"))
    .nullable()
    .default(null),
  voorbeeldkapitaal2Percentage: Yup.number()
    .min(0, getHypotheekTextResources("ErrorVoorbeeldKapitaalPercentageMin"))
    .max(20, getHypotheekTextResources("ErrorVoorbeeldKapitaalPercentageMax"))
    .nullable()
    .default(null)
});
export const kapitaalopbouwSchema = kapitaalopbouwSchemaYup;

export const leningdeelgegevensSchemaYup = Yup.object({
  leningDeelId: Yup.string().nullable(),
  renteVariantenWaarschuwingText: Yup.string().default(getHypotheekTextResources("renteVastPeriodeWaarschuwing")),
  oorspronkelijkeHoofdsom: Yup.number()
    .nullable()
    .default(null)
    .test("max-check", "", function(): true | Yup.ValidationError {
      const context = this.options.context as HypotheekSchemaContextType;
      if (context.situatie === "huidig") {
        if ((this.parent.oorspronkelijkeHoofdsom ?? 0) === 0) {
          return this.createError({
            message: getHypotheekTextResources("ErrorBedragLeningdeel")
          });
        }
        if (context.oorspronkelijkeHoofdsomTotaalError) {
          return this.createError({
            message: getHypotheekTextResources("OorspronkelijkeHoofdsomTotaleHypotheekError")
          });
        }
      }
      return true;
    }),
  garantie: Yup.mixed<Garantiesoort | null>()
    .oneOf([...Object.values(Garantiesoort), null], getHypotheekTextResources("ErrorOnbekendeWaarde"))
    .default(null),
  leningdeelHoofdsom: berekenInputSchema
    .default({ bedrag: null, berekenen: null, berekendBedrag: null })
    .test("max-check", "", function(): true | Yup.ValidationError {
      const context = this.options.context as HypotheekSchemaContextType;
      if (context.situatie === "huidig") {
        if (this.parent.leningdeelHoofdsom.bedrag <= this.parent.oorspronkelijkeHoofdsom) {
          return true;
        }
        return this.createError({
          message: getHypotheekTextResources("RestantHoofdsomOorspronkelijkeHoofdsomError")
        });
      }
      if (context.leningdeelHoofdsomTotaalError) {
        return this.createError({
          message: getHypotheekTextResources("LeningdeelbedragTotaleHypotheekError"),
          path: `${this.path}.bedrag`
        });
      }
      return true;
    }),
  renteVariant: Yup.mixed<RentevariantOptions>()
    .oneOf([...Object.values(RentevariantOptions)], getHypotheekTextResources("ErrorOnbekendeWaarde"))
    .default(RentevariantOptions.Rentevast),
  rentevastPeriodeJaar: Yup.number()
    .test({
      message: getHypotheekTextResources("RentevastPeriodeJaarError"),
      test: (val): boolean => {
        if (isNaN(val)) return false;
        const periodeJaar = Number(val);
        if (periodeJaar < 0 || periodeJaar > 30) return false;
        return true;
      }
    })
    .nullable()
    .default(10),
  rentevastperiodeKeuze: Yup.number()
    .nullable()
    .default(10),
  renteBedenktijdJaar: Yup.number()
    .nullable()
    .default(null),
  einddatum: nullableLocalDateSchema,
  automatischeRentedaling: Yup.boolean().default(false),
  automatischeRentedalingModal: rentedalingObvMarktwaardePercentageModalSchema,
  renteScenarioModal: renteScenarioModalSchema,
  rentePercentage: berekenInputSchema
    .test(
      "maxRente",
      getHypotheekTextResources("ErrorRentePercentageMax"),
      (val: Yup.InferType<typeof berekenInputSchema>): boolean => {
        if (val && ((val.bedrag && val.bedrag > 20) || (val.berekendBedrag && val.berekendBedrag > 20))) return false;
        return true;
      }
    )
    .nullable()
    .default({ bedrag: 0, berekendBedrag: 0, berekenen: true }),
  periodiekeAflossing: Yup.number()
    .nullable()
    .default(null),
  extraAflossing: extraAflossingenModalSchema,
  datumOpgave: nullableLocalDateSchema.test({
    message: getHypotheekTextResources("ErrorDatumOpgaveEinddatum"),
    test: function(value: LocalDate | null) {
      const context = this.options.context as HypotheekSchemaContextType;
      if (context.situatie === "voorstel") return true;

      if (hasValue(value)) {
        return value <= LocalDate.now();
      }
      return true;
    }
  })
});

export const leningdeelgegevensSchema = leningdeelgegevensSchemaYup;

// TODO: bij aanmaken defaults voor kaart gebruiken.
const gekoppeldProductSchemaYup = Yup.object({}).default({});
export const gekoppeldProductSchema = gekoppeldProductSchemaYup;

const opmerkingSchemaYup = Yup.object({}).default({});
export const opmerkingSchema = opmerkingSchemaYup;

const hypotheekVormSchemaYup = Yup.object({
  code: Yup.number().default(0),
  omschrijving: Yup.string().default(""),
  aflossingsvorm: Yup.mixed<AflossingsVormType>()
    .oneOf(Object.values(AflossingsVormType), getHypotheekTextResources("ErrorOnbekendeWaarde"))
    .default(AflossingsVormType.Annuïteit),
  isStartersLening: Yup.boolean().default(false),
  isRestschuldLening: Yup.boolean().default(false)
});

export const hypotheekVormSchema = hypotheekVormSchemaYup;

export const productKenmerkenLeningdeelSchema = Yup.object({
  product: Yup.object({
    maatschappijkeuzeEnabled: Yup.boolean(),
    logoTonen: Yup.boolean(),
    productoptiesTonen: Yup.boolean(),
    productnummerTonen: Yup.boolean(),
    ingangsdatumTonen: Yup.boolean(),
    einddatumTonen: Yup.boolean(),
    uwBemiddelingTonen: Yup.boolean(),
    einddatumEnabled: Yup.boolean(),
    looptijdTonen: Yup.boolean(),
    looptijdJaarEnabled: Yup.boolean(),
    looptijdMaandEnabled: Yup.boolean(),
    productnaamTonen: Yup.boolean(),
    productnaamEnabled: Yup.boolean()
  }).default({
    maatschappijkeuzeEnabled: true,
    logoTonen: true,
    productoptiesTonen: true,
    productnummerTonen: true,
    ingangsdatumTonen: true,
    einddatumTonen: true,
    uwBemiddelingTonen: true,
    einddatumEnabled: true,
    looptijdTonen: true,
    looptijdJaarEnabled: true,
    looptijdMaandEnabled: true,
    productnaamTonen: true,
    productnaamEnabled: true
  }),
  leninggegeven: Yup.object({
    heeftScenarioKorting: Yup.boolean(),
    heeftPeriodiekeOpname: Yup.boolean(),
    maximaleOpnameDuurMaanden: Yup.number(),
    minimaleOpnameDuurMaanden: Yup.number()
  }).default({
    heeftScenarioKorting: false,
    heeftPeriodiekeOpname: false,
    maximaleOpnameDuurMaanden: 360,
    minimaleOpnameDuurMaanden: 1
  }),
  persoonkeuze: Yup.object({
    contractantenSelectieTonen: Yup.boolean(),
    verzekerdeSelectie: Yup.boolean(),
    verzekeringnemerSelectie: Yup.boolean(),
    premiesplitsingTonen: Yup.boolean(),
    meerdereVerzekerdeOptiesMogelijk: Yup.boolean(),
    meerdereVerzekeringnemerOptiesMogelijk: Yup.boolean(),
    meerdereContractantenOptiesMogelijk: Yup.boolean()
  }).default({
    contractantenSelectieTonen: false,
    verzekerdeSelectie: false,
    verzekeringnemerSelectie: false,
    premiesplitsingTonen: false,
    meerdereVerzekerdeOptiesMogelijk: false,
    meerdereVerzekeringnemerOptiesMogelijk: false,
    meerdereContractantenOptiesMogelijk: false
  }),
  dekking: Yup.object({
    overlijdensrisicodekkingTonen: Yup.boolean()
  }).default({
    overlijdensrisicodekkingTonen: false
  }),
  kapitaalopbouw: Yup.object({
    heeftScenarioKorting: Yup.boolean(),
    kapitaalopbouwTonen: Yup.boolean(),
    doelkapitaalEnabled: Yup.boolean(),
    rekenrendementEnabled: Yup.boolean(),
    soortVerzekeringTonen: Yup.boolean(),
    beleggersprofielTonen: Yup.boolean(),
    soortBerekeningTonen: Yup.boolean()
  }).default({
    heeftScenarioKorting: false,
    kapitaalopbouwTonen: false,
    doelkapitaalEnabled: false,
    rekenrendementEnabled: false,
    soortVerzekeringTonen: false,
    beleggersprofielTonen: false,
    soortBerekeningTonen: false
  }),
  fiscaleRegeling: Yup.object({
    lijfrenteclausuleTonen: Yup.boolean(),
    fiscaleRegelingTonen: Yup.boolean(),
    kapitaalopbouwBoxKeuzeTonen: Yup.boolean(),
    orvBoxKeuzeTonen: Yup.boolean(),
    kapitaalopbouwOpKaartTonen: Yup.boolean(),
    lijfrenteclausuleOpKaartTonen: Yup.boolean()
  }).default({
    lijfrenteclausuleTonen: false,
    fiscaleRegelingTonen: false,
    kapitaalopbouwBoxKeuzeTonen: false,
    orvBoxKeuzeTonen: false,
    kapitaalopbouwOpKaartTonen: false,
    lijfrenteclausuleOpKaartTonen: false
  }),
  premie: Yup.object({
    aanvangstortingEnabled: Yup.boolean(),
    aanvangstortingTonen: Yup.boolean(),
    aftrekbarePremieTonen: Yup.boolean(),
    berekenPremieKnopTonen: Yup.boolean(),
    betalingstermijnEnabled: Yup.boolean(),
    betalingstermijnTonen: Yup.boolean(),
    componentKenmerk: Yup.boolean(),
    duurHoogLaagConstructieTonen: Yup.boolean(),
    duurHoogLaagConstructieEnabled: Yup.boolean(),
    duurPremiebetalingEnabled: Yup.boolean(),
    duurPremiebetalingTonen: Yup.boolean(),
    einddatumPremiebetalingEnabled: Yup.boolean(),
    einddatumPremiebetalingTonen: Yup.boolean(),
    extraStortingTonen: Yup.boolean(),
    garantiePremieTonen: Yup.boolean(),
    heeftBetalingstermijnHalfJaar: Yup.boolean(),
    heeftBetalingstermijnJaar: Yup.boolean(),
    heeftBetalingstermijnKoopsom: Yup.boolean(),
    heeftBetalingstermijnKwartaal: Yup.boolean(),
    heeftBetalingstermijnMaand: Yup.boolean(),
    heeftBetalingstermijnTweeMaanden: Yup.boolean(),
    heeftPremieverloopGelijkblijvend: Yup.boolean(),
    heeftPremieverloopVariabel: Yup.boolean(),
    hintAanvangstortingTonen: Yup.boolean(),
    hintRisicopremiespecificatieTonen: Yup.boolean(),
    hoogLaagConstructieTonen: Yup.boolean(),
    hoogLaagVerhoudingTonen: Yup.boolean(),
    ingebrachteWaardeTonen: Yup.boolean(),
    premiebetalingEnabled: Yup.boolean(),
    premiedepotTonen: Yup.boolean(),
    premieHoogEnabled: Yup.boolean(),
    premiestijgendTonen: Yup.boolean(),
    premieverloopTonen: Yup.boolean(),
    premiegegevensTonen: Yup.boolean(),
    risicopremieEnabled: Yup.boolean(),
    risicopremiespecificatieHint: Yup.string(),
    risicopremiespecificatieTonen: Yup.boolean(),
    risicopremieTonen: Yup.boolean(),
    soortDekking: Yup.boolean(),
    spaarpremieEnabled: Yup.boolean(),
    spaarpremieTonen: Yup.boolean(),
    totalePremieEnabled: Yup.boolean(),
    totalePremieTonen: Yup.boolean(),
    verkortePremieduurTonen: Yup.boolean(),
    hintPremieAftrekbaarTonen: Yup.boolean(),
    hintPremieAftrekbaar: Yup.string()
  }).default({
    aanvangstortingEnabled: true,
    aanvangstortingTonen: true,
    aftrekbarePremieTonen: true,
    berekenPremieKnopTonen: true,
    betalingstermijnEnabled: true,
    betalingstermijnTonen: true,
    componentKenmerk: true,
    duurHoogLaagConstructieTonen: true,
    duurHoogLaagConstructieEnabled: true,
    duurPremiebetalingEnabled: true,
    duurPremiebetalingTonen: true,
    einddatumPremiebetalingEnabled: true,
    einddatumPremiebetalingTonen: true,
    extraStortingTonen: true,
    garantiePremieTonen: true,
    heeftBetalingstermijnHalfJaar: true,
    heeftBetalingstermijnJaar: true,
    heeftBetalingstermijnKoopsom: true,
    heeftBetalingstermijnKwartaal: true,
    heeftBetalingstermijnMaand: true,
    heeftBetalingstermijnTweeMaanden: true,
    heeftPremieverloopGelijkblijvend: true,
    heeftPremieverloopVariabel: true,
    hintAanvangstortingTonen: true,
    hintRisicopremiespecificatieTonen: true,
    hoogLaagConstructieTonen: true,
    hoogLaagVerhoudingTonen: true,
    ingebrachteWaardeTonen: true,
    premiebetalingEnabled: true,
    premiedepotTonen: true,
    premieHoogEnabled: true,
    premiestijgendTonen: true,
    premieverloopTonen: true,
    premiegegevensTonen: true,
    risicopremieEnabled: true,
    risicopremiespecificatieHint: "",
    risicopremiespecificatieTonen: true,
    risicopremieTonen: true,
    soortDekking: true,
    spaarpremieEnabled: true,
    spaarpremieTonen: true,
    totalePremieEnabled: true,
    totalePremieTonen: true,
    verkortePremieduurTonen: true,
    hintPremieAftrekbaarTonen: true,
    hintPremieAftrekbaar: ""
  }),
  gekoppeldProduct: Yup.object({
    hintGekoppeldProduct: Yup.string(),
    gekoppeldProductTonen: Yup.boolean()
  }).default({
    hintGekoppeldProduct: "",
    gekoppeldProductTonen: false
  }),
  opmerking: Yup.object({
    hintOpmerking: Yup.string(),
    opmerkingTonen: Yup.boolean()
  }).default({
    hintOpmerking: "",
    opmerkingTonen: false
  })
});

export const hypotheekSchema = Yup.object({
  //productKenmerkenContext: productKenmerkenLeningdeelSchema,
  nearMatchHypotheekvormen: Yup.array(hypotheekVormSchema).nullable(),
  volgnummer: Yup.number(),
  renteBoxSoort: Yup.number().nullable(),
  partijCode: Yup.string().default("XX"),
  productCode: Yup.string().default("01"),
  labelCode: Yup.number()
    .default(null)
    .nullable(),
  labelNaam: Yup.string(),
  energieKlasse: yupNullableEnum(EnergieKlasseType).default(null),
  renteBoxMaatschappijCode: Yup.string()
    .nullable()
    .default(null),
  marktwaardeWaarschuwingTonen: Yup.boolean().default(false),
  hypotheekVorm: hypotheekVormSchema,
  product: hypotheekProductSchema,
  hypotheekProductDetails: hypotheekProductDetailsSchema.nullable(),
  schuldenaars: schuldenaarsSchema,
  leningdeelgegevens: leningdeelgegevensSchema,
  opnameSoort: Yup.mixed<SoortOpnamesUitkering>()
    .default(null)
    .nullable(), //indien null dan is het geen kredietNoPay, dus geen opnames
  opnameBedrag: Yup.number()
    .test(
      "max-opname-bedrag",
      "",
      /* istanbul ignore next */ function(value: number): true | Yup.ValidationError {
        if (!hasValue(this.parent.opnameSoort)) return true;
        const context = this.options.context as HypotheekSchemaContextType;

        // Wanneer het totaal van opnames * bedrag > leningdeelHoofdsom dan fout validatie.
        if (this.parent.opnameMaanden * value > this.parent.leningdeelgegevens.leningdeelHoofdsom.bedrag) {
          return this.createError({
            path: `${this.path}`,
            message: `Het totaal van de opnames mag niet hoger zijn dan de leningdeel hoofdsom.`
          });
        }

        const opnameMaanden = this.parent.opnameMaanden || 1;
        const maximalePeriodeBedrag =
          this.parent.soortOpname === SoortOpnamesUitkering.Periodiek
            ? this.parent.leningdeelgegevens.leningdeelHoofdsom.bedrag / opnameMaanden
            : this.parent.leningdeelgegevens.leningdeelHoofdsom.bedrag;

        if (context.situatie === "voorstel") {
          if (this.parent.soortOpname !== null && !value) {
            return this.createError({
              path: `${this.path}`,
              message: `Bedrag is een verplicht veld.`
            });
          }

          if (this.parent.soortOpname !== null && hasValue(maximalePeriodeBedrag) && value > maximalePeriodeBedrag) {
            return this.createError({
              path: `${this.path}`,
              message: `Totale opname (${bedragFormat(value)}) overschrijdt de opnamelimiet (${bedragFormat(
                maximalePeriodeBedrag
              )}).`
            });
          }
        }

        return true;
      }
    )
    .nullable(),
  opnameMaanden: Yup.number()
    .nullable()
    .max(360, "Aantal maanden moet kleiner of gelijk zijn aan 360")
    .test(
      "max-duur",
      "",
      /* istanbul ignore next */ function(value: number): true | Yup.ValidationError {
        if (!hasValue(this.parent.opnameSoort)) return true;

        const context = this.options.context as HypotheekSchemaContextType;
        if (!context.productKenmerken) return true;
        const huidigProductKenmerken = getHuidigeProductKenmerken(
          this.parent.partijCode,
          this.parent.productCode,
          context.productKenmerken
        );
        if (!huidigProductKenmerken || isKenmerkError(huidigProductKenmerken)) return true;

        const maxLooptijd =
          this.parent.opnameSoort === SoortOpnamesUitkering.Eenmalig
            ? 1
            : huidigProductKenmerken?.leninggegeven.maximaleOpnameDuurMaanden;

        const minLooptijd =
          this.parent.opnameSoort === SoortOpnamesUitkering.Eenmalig
            ? 1
            : huidigProductKenmerken?.leninggegeven.minimaleOpnameDuurMaanden;

        if (context.situatie === "voorstel") {
          if (this.parent.opnameSoort !== null && !hasValue(value)) {
            return this.createError({
              path: `${this.path}`,
              message: `Maanden is een verplicht veld.`
            });
          }
          if (this.parent.opnameSoort !== null && value > maxLooptijd) {
            return this.createError({
              path: `${this.path}`,
              message: `Het aantal opnames mag niet groter zijn dan ${maxLooptijd} maanden.`
            });
          }
          if (this.parent.opnameSoort !== null && value < minLooptijd) {
            return this.createError({
              path: `${this.path}`,
              message: `Het aantal opnames mag niet kleiner zijn dan ${minLooptijd} maanden.`
            });
          }
        }
        return true;
      }
    )
    .min(1, "Aantal maanden moet groter of gelijk zijn aan 1")
    .default(null),
  fiscalegegevens: fiscalegegevensSchema,
  gekoppeldProduct: gekoppeldProductSchema,
  opmerking: opmerkingSchema,
  isNieuw: Yup.boolean().default(false),
  soortOnderpand: Yup.mixed<Financieringsoort>()
    .oneOf(Object.values(Financieringsoort), getProductenOverzichtTextResources("ErrorOnbekendeWaarde"))
    .default(Financieringsoort.Geen),
  verzekerde: verzekerdenSchema,
  verzekeringnemers: verzekeringnemersSchema,
  kapitaalopbouw: kapitaalopbouwSchema.nullable().default(null),
  fiscaleRegeling: hypotheekFiscaleRegelingSchema.nullable().default(null),
  premieGegevens: premieGegevensSchema.nullable().default(null),
  hypotheekOptiesIngPriceToolLeningdeel: hypotheekOptiesIngPriceToolLeningdeelSchema.nullable()
});

export const hypotheekSelectieSamenstellingSchema = Yup.object({
  nearMatchHypotheekvormen: Yup.array(hypotheekVormSchema).nullable(),
  matchHypotheekvormen: Yup.array(hypotheekVormSchema).nullable(),
  huidigeHypotheekvorm: hypotheekVormSchema.nullable(),
  productCode: Yup.string().default("01"),
  hypotheekVorm: hypotheekVormSchema.nullable(),
  product: hypotheekProductSchema,
  leningdeelgegevens: leningdeelgegevensSchema,
  fiscalegegevens: fiscalegegevensSchema,
  schuldenaars: schuldenaarsSchema,
  isNieuw: Yup.boolean().default(false),
  volgnummer: Yup.number().default(1),
  fiscaleRegeling: hypotheekFiscaleRegelingSchema.nullable().default(null),
  hypotheekProductDetails: hypotheekProductDetailsSchema.nullable().default(null)
});

export const leningdeelKeuzeModalSchema = Yup.object({
  nearMatchHypotheekvorm: Yup.array(Yup.string()).nullable(),
  maatschappijProductCode: maatschappijProductHuidigSchema,
  leningdeelKeuze: Yup.mixed<"genereer" | "samenstelling" | "keuzeproduct">().default("keuzeproduct"),
  productCode: aflosProductSchema,
  hasProducten: Yup.boolean()
    .test("has producten", "Er zijn geen producten beschikbaar", val => val)
    .default(true),
  leningdelen: Yup.array(hypotheekSchema),
  soortFinanciering: yupNullableEnum(Financieringsoort)
    .nullable()
    .default(null)
});

export const leningdeelKeuzeVoorstelModalSchema = leningdeelKeuzeModalSchema.shape({
  maatschappijProductCode: maatschappijProductVoorstelSchema,
  productCode: aflosProductVoorstelSchema,
  leningdelen: Yup.array(
    hypotheekSchema.shape({
      fiscaleRegeling: Yup.object().nullable(),
      fiscalegegevens: Yup.object()
    })
  )
});

export const pandSchema = Yup.object({
  pandId: Yup.string(),
  marktwaardeRenteBedrag: Yup.number().nullable(),
  bevoorschottingspercentage: Yup.number().nullable(),
  marktwaardeLtv: berekenInputSchema,
  gebruikPand: Yup.mixed<GebruikPandSoort>().oneOf(
    Object.values(GebruikPandSoort),
    getHypotheekTextResources("ErrorOnbekendeWaarde")
  ),
  totaleHypotheekBedrag: Yup.number().nullable(),
  bewoners: yupEnum(AanvragerKeuze).default(AanvragerKeuze.Aanvrager1)
});

export const fiscaleVoortzettingKeuzeSchema = Yup.object({
  omschrijving: Yup.string().nullable(),
  productId: Yup.string().nullable(),
  productkenmerken: Yup.object({
    doelkapitaalBedrag: Yup.number().nullable(),
    eerdereUitkeringenBedrag: Yup.number().nullable(),
    einddatum: nullableLocalDateSchema,
    externeMaatschappijCode: Yup.string().nullable(),
    externeMaatschappijOmschrijving: Yup.string().nullable(),
    fiscaalRegime: yupNullableEnum(FiscaalRegimeOptions),
    fiscaleTypering: yupNullableEnum(FiscaleTyperingOptions),
    garantieverzekering: Yup.boolean().nullable(),
    hoogstePremieOoitBedrag: Yup.number().nullable(),
    huidigeJaarPremieBedrag: Yup.number().nullable(),
    ingangsdatumBox1: nullableLocalDateSchema,
    ingebrachteWaardeBedrag: Yup.number().nullable(),
    kapitaalopbouw: yupNullableEnum(KapitaalopbouwOptions),
    laagstePremieooitBedrag: Yup.number().nullable(),
    lijfrenteclausuleOrigineel: Yup.boolean().nullable(),
    oorspronkelijkeIngangsdatum: nullableLocalDateSchema,
    originelePolisnummer: Yup.string().nullable(),
    polisnummer: Yup.string().nullable(),
    premieLopendJaarBedrag: Yup.number().nullable(),
    productId: Yup.string().nullable()
  }).nullable()
});

export const offerteSchema = Yup.object({
  minimaleOfferteGeldigheidsDuur: Yup.bool(),
  geldigheidsduurMinimaal: Yup.number()
    .test({
      message: getHypotheekVergelijkenTextResources("OnbepaaldeOfferteDuur"),
      test: function(value: number | null) {
        if (this.parent.minimaleOfferteGeldigheidsDuur) {
          return hasValue(value) && value >= 1 && value <= 12;
        }
        return true;
      }
    })
    .nullable(),
  inclusiefVerlengingsduur: Yup.boolean()
});

export const contractRenteSchema = Yup.object({
  offerteRente: Yup.bool(),
  dagRente: Yup.bool(),
  dalRente: Yup.bool()
});
export const overigeSchema = Yup.object({
  annuleringsKostenAcceptabel: Yup.bool(),
  verplichteOrv: Yup.bool(),
  boeteBijVerkoop: Yup.bool(),
  verhuisRegeling: Yup.bool()
});
export const verklaringInkomenSchema = Yup.object({
  werkgever: Yup.bool(),
  uwv: Yup.bool(),
  perspectief: Yup.bool(),
  accountantsverklaring: Yup.bool(),
  ibStukken: Yup.bool()
});

export const voorwaardenSelectieSchema = Yup.object({
  offerte: offerteSchema.nullable(),
  contractRente: contractRenteSchema.nullable(),
  overige: overigeSchema.nullable(),
  verklaringInkomen: verklaringInkomenSchema.nullable()
});

export const hypotheeklabelSchema = Yup.object({
  partijCode: Yup.string()
    .nullable()
    .default(null),
  labelCode: Yup.number()
    .nullable()
    .default(null),
  labelNaam: Yup.string()
    .nullable()
    .default(null)
});

export const hypothekenBaseSchema = Yup.object({
  benodigdHypotheekbedragIngevuld: Yup.bool().nullable(),
  hypotheeklabel: hypotheeklabelSchema.nullable(),
  producten: Yup.array(hypotheekSchema).default([hypotheekSchema.default()]),
  aanvrager1: klantnaamSchema.nullable(),
  aanvrager2: klantnaamSchema.nullable(),
  panden: Yup.array(pandSchema),
  energieKlasse: yupNullableEnum(EnergieKlasseType).default(null),
  eigenwoningschuldBedrag: Yup.number().nullable(),
  soortFinanciering: Yup.mixed<Financieringsoort>()
    .oneOf(Object.values(Financieringsoort), getProductenOverzichtTextResources("ErrorOnbekendeWaarde"))
    .default(Financieringsoort.Geen),
  aanvangsdatum: nullableLocalDateSchema,
  hypotheekOptie: hypotheekOptieSchema,
  gewensteHypotheek: Yup.number()
    .nullable()
    .default(null),
  hypotheekOptiesIngPriceTool: hypotheekOptiesIngPriceToolSchema.nullable(),
  meerwerkEBV: Yup.number().nullable(),
  nhg: Yup.boolean().nullable(),
  fiscaleVoortzettingKeuzes: Yup.array(fiscaleVoortzettingKeuzeSchema).nullable(),
  overbruggingBedrag: Yup.number()
    .nullable()
    .default(null),
  voorwaardenSelectie: Yup.object({
    verklaringInkomen: verklaringInkomenSchema
  })
    .nullable()
    .default(null)
  // tempData: Yup.object({
  //   selected: Yup.number()
  // })
});

// export type HypotheekSamenstellingModalType = Yup.InferType<typeof hypotheekSamenstellingModalSchema>;
