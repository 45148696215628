import React, { ReactElement, useMemo, useCallback } from "react";
import { useRequestInit } from "adviesbox-shared";
import { useFetchData } from "../../../shared/components/dossier/dossier-wijzig-adviseur-modal/usefetchdata";
import {
  SnelInzichtProductoverzichtenOutput,
  SnelInzichtProduct
} from "../../../.generated/rapportage/rapportagetypes";
import "./product-overzicht.scss";
import { CardLoadingSpinner } from "../scenarios/card-loading-spinner";

  /* eslint-disable react/prop-types */
export const ProductOverzicht: React.FC = ():ReactElement => {
  const { settings, params, requestInit } = useRequestInit<{
    vestiging: string;
    adviesdossier: string;
    voorstel: string;
  }>();


  const getProductenUrl = useMemo(() => {
    const baseUrl = `${settings.rapportageOrigin}`;
    return params.voorstel
      ? `${baseUrl}/Voorstellen/${params.voorstel}/SnelInzicht/Productoverzicht`
      : `${baseUrl}/Adviesdossiers/${params.adviesdossier}/SnelInzicht/Productoverzicht`;
  }, [settings.rapportageOrigin, params.adviesdossier, params.voorstel]);

  
  const getRequestInit = useCallback(
    () => ({
      ...requestInit,
      url: getProductenUrl
    }),
    [requestInit, getProductenUrl]
  );

  const { data, loading, error } = useFetchData<SnelInzichtProductoverzichtenOutput>(
    getProductenUrl,
    getRequestInit
  );

  if (error) return <div>Error: {error}</div>;

  const dataKey = Object.keys(data?.productoverzicht || {})[0];
  const producten = data?.productoverzicht?.[dataKey]?.producten || [];
  const groupedProducts = producten.reduce((acc, product) => {
    const { productsoort } = product;
    /* istanbul ignore else */
    if (!acc[productsoort]) {
      acc[productsoort] = [];
    }
    acc[productsoort].push(product);
    return acc;
  }, {} as Record<string, SnelInzichtProduct[]>);

  const renderProductList = (products: SnelInzichtProduct[], title: string): false | ReactElement => (
    products.length > 0 && (
      <div key={title}>
        <h2>{title}</h2>
        <ul className="product-overzicht__list">
          {products.map((product, index) => (
            <li className="product-overzicht__item" key={`${product.productId}-${index}`}>
              <div className="product-overzicht__text">
                {product.maatschappijNaam ?? ""} {product.productnaam ?? "Geen product naam"}
              </div>
            </li>
          ))}
        </ul>
      </div>
    )
  );

  return (
    <div className="product-overzicht">
      { loading && <CardLoadingSpinner />}
      {Object.entries(groupedProducts).map(([productsoort, products]) =>
        renderProductList(products, productsoort)
      )}
    </div>
  );
};
