import {
  HypotheeklabelMetHypotheekopties,
  HypotheeklabelMetHypotheekoptiesOutput,
  HypotheekvormBasis
} from "../../.generated/producten/productentypes";
import { sortByStringOrder } from "../../shared/utils/sort-string";
import { LabelValuePairs, LabelValuePair } from "../../shared/types";
import { SituatieSoort } from "../../producten-overzicht/infra/producten-overzicht-types";
import { HypotheekSelectieSamenstellingType, HypotheekVormType, HypothekenState } from "../infra/hypotheek-types";
import { partijOnafhankelijk } from "../../producten-overzicht/infra/product-constanten";
import { Financieringsoort } from "../../.generated/forms/formstypes";

export type MaatschappijNaamType = { code: string; naam: string };

export const isAankoop = (v: Financieringsoort | null): boolean => {
  const AankoopValues = Object.values(Financieringsoort).filter(f => f.includes("Aankoop"));
  return AankoopValues.some(av => av === v);
};

export const getHuidigeMaatschappijCode = (hypotheken: HypothekenState): string | null => {
  if (hypotheken?.producten === null) return null;
  return hypotheken.producten.find(product => !product.isNieuw)?.product.partijCodeSelectie ?? null;
}

export function mapHypotheekSelectie(values: HypotheeklabelMetHypotheekoptiesOutput): LabelValuePairs {
  if (!values.producten) throw Error("Er zijn geen hypotheek-labels gevonden.");

  const guids = Object.keys(values.producten);

  const maatschappijen = guids
    .map(
      (guid): Partial<HypotheeklabelMetHypotheekopties> => {
        return values.producten ? values.producten[guid] : /* istanbul ignore next */ {};
      }
    )
    .map((product): { label: string; value: string } => {
      return {
        label: product.productnaam || "",
        value: `${product.maatschappijCode}_${product.code}`
      };
    });

  return maatschappijen;
}

export const mapAflosvormProducten = (
  situatie: SituatieSoort,
  producten: { [index: string]: HypotheekvormBasis },
  isRestschuldHypotheek: boolean
): LabelValuePairs => {
  const guids = Object.keys(producten);
  const isStartersOnafhankelijk = (index: string): boolean =>
    producten[index].maatschappijCode === partijOnafhankelijk && producten[index].productnaam === "Starterslening";
  return guids
    .filter(c => {
      return situatie === "huidig"
        ? true
        : producten[c].restschuldFinanciering === isRestschuldHypotheek && isStartersOnafhankelijk(c) === false;
    })
    .map(
      (id): LabelValuePair => ({
        label: producten[id]?.productnaam || "",
        value: producten[id] === null ? "" : `${producten[id].code}`
      })
    )
    .sort((a, b) => {
      return sortByStringOrder(a.label, b.label);
    });
};

export const getUniqueMaatschappijenFromProducten = (
  producten: {
    [key: string]: HypotheeklabelMetHypotheekopties;
  } | null
): MaatschappijNaamType[] => {
  if (producten) {
    const guids = Object.keys(producten);
    const allMaatschappijen = new Array<MaatschappijNaamType>();
    guids.forEach((c: string) => {
      const code = producten[c].maatschappijCode;
      const naam = producten[c].maatschappijNaamKort;

      /* istanbul ignore next */
      if (!code || !naam) throw Error("invalid data");

      allMaatschappijen.push({
        code: code,
        naam: naam
      });
    });

    const uniqueMaatschappijen = new Array<MaatschappijNaamType>();

    allMaatschappijen.forEach(k => {
      if (uniqueMaatschappijen.find(c => c.code === k.code) === undefined) {
        uniqueMaatschappijen.push(k);
      }
    });

    return uniqueMaatschappijen;
  }
  return [];
};

export function getProductnaam(
  values: HypotheeklabelMetHypotheekoptiesOutput,
  maatschappijCode: string,
  labelCode: number
): string {
  /* istanbul ignore next */
  if (!values.producten) throw new Error("geen producten beschikbaar");

  const label = Object.keys(values.producten)
    .map(
      (guid): Partial<HypotheeklabelMetHypotheekopties> => {
        return values.producten ? values.producten[guid] : /* istanbul ignore next */ {};
      }
    )
    .find(c => c.maatschappijCode === maatschappijCode && c.code === labelCode);

  return label?.productnaam ?? "";
}

export function getOptions(leningdeel: HypotheekSelectieSamenstellingType): LabelValuePair[] {
  return (
    (leningdeel.nearMatchHypotheekvormen &&
      leningdeel.nearMatchHypotheekvormen.map(
        (c: HypotheekVormType, index: number): LabelValuePair => {
          return { label: c.omschrijving, value: `${leningdeel.volgnummer}_${index}` };
        }
      )) ||
    (leningdeel.matchHypotheekvormen &&
      leningdeel.matchHypotheekvormen.map(
        (c: HypotheekVormType, index: number): LabelValuePair => {
          return { label: c.omschrijving, value: `${leningdeel.volgnummer}_${index}` };
        }
      )) ||
    []
  );
}
