import {
  CardWrapper,
  FormFirstFocus,
  PlatformFoutenSamenvatting,
  useRequestInit,
  NotificatiesContext,
  LoadingSpinner
} from "adviesbox-shared";
import { connect, Form, FormikContextType } from "formik";
import React, { ReactElement, useContext, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { DevDebug } from "../../dev-debug/dev-debug";
import { renderAanvullendeGegevens } from "./aanvullende-gegevens-helper";
import { AanvullendeGegevensType } from "./aanvullende-gegevens-schema";
import classes from "./DossierbalkFoutenDropdown.module.scss";
import { nieuweAanvraagPost } from "./aanvullende-gegevens-modal-api";
import DossierAanvraagFoutenContext from "../../../dossier-aanvraag-fouten/dossier-aanvraag-fouten-context";

/* istanbul ignore file */
type AanvullendeGegevensProps = {
  onSave?: (data: any) => void;
  closeModal?: () => void;
};

const AanvullendeGegevensModal = ({
  closeModal,
  formik
}: AanvullendeGegevensProps & {
  formik: FormikContextType<AanvullendeGegevensType>;
}): ReactElement => {
  const { values } = formik;
  const { settings, user, params } = useRequestInit<{ vestiging: string; adviesdossier: string }>();
  const { setAanvullendeGegevens } = useContext(DossierAanvraagFoutenContext);
  const { subscribeCorrelationId } = useContext(NotificatiesContext);
  const { setNieuweAanvraag, nieuweAanvraag } = useContext(DossierAanvraagFoutenContext);

  useEffect(() => {
    if (nieuweAanvraag && nieuweAanvraag.closeModal) {
      setNieuweAanvraag({ ...nieuweAanvraag, closeModal: false });
      closeModal && closeModal();
    }
  }, [closeModal, nieuweAanvraag, setNieuweAanvraag]);
  
  return (
    <>
      <Modal.Header>
        <div className="d-flex w-100">
          <Modal.Title>Aanvullende gegevens invullen</Modal.Title>
          <button type="button" className={"close ml-auto"} onClick={closeModal} id={`close-modal`}>
            <span aria-hidden="true">×</span>
            <span className="sr-only">Close</span>
          </button>
        </div>
      </Modal.Header>
      <Modal.Body>
        <>
          <PlatformFoutenSamenvatting className={classes.platform_errors} />
          <FormFirstFocus>
            <Form>
              <div className="d-flex flex-wrap flex-row">
                <CardWrapper flexType="flex-column" className="pl-3">
                  <div className={classes.aanvullende_card}>{renderAanvullendeGegevens(values)}</div>
                </CardWrapper>
              </div>

              <DevDebug />
            </Form>
          </FormFirstFocus>
        </>
      </Modal.Body>
      <Modal.Footer className={classes.modal_footer}>
        <button
          type="button"
          className="btn btn-link"
          id="annuleer-aanvullende-gegevens"
          data-testid="annuleer-aanvullende-gegevens"
          disabled={nieuweAanvraag?.isloading ?? false}
          onClick={closeModal}
        >
          Sluiten
        </button>

        {nieuweAanvraag?.isloading ?? false ? (
          <button data-testid="btn-fetch" type="button" id="fetch-button" className={`btn btn-primary`}>
            <LoadingSpinner size="S" />
          </button>
        ) : (
          <button
            type="button"
            className="btn btn-primary"
            id="bevestig-aanvullende-gegevens"
            data-testid="bevestig-aanvullende-gegevens"
            onClick={async () => {
              setAanvullendeGegevens(values);
              // eslint-disable-next-line @typescript-eslint/no-floating-promises
              const result = await nieuweAanvraagPost(settings, user, params.vestiging, values);
              setNieuweAanvraag({
                isloading: true,
                receivedId: result,
                partijCode: nieuweAanvraag?.partijCode ?? null,
                productType: nieuweAanvraag?.productType ?? null,
                closeModal: false
              });
              subscribeCorrelationId(result);
            }}
          >
            Aanvullende gegevens opslaan
          </button>
        )}
      </Modal.Footer>
    </>
  );
};

AanvullendeGegevensModal.displayName = "AanvullendeGegevensModal";
export default connect<AanvullendeGegevensProps, AanvullendeGegevensType>(AanvullendeGegevensModal);
