import React, { ReactElement, ReactNode, useState, useEffect, useContext } from "react";
import DossierAanvraagFoutenContext, { DossierFoutenContextType } from "./dossier-aanvraag-fouten-context";
import {
  AanvullendeGegevensType,
  aanvullendeGegevensSchema
} from "../components/dossier/dossierbalk-fouten-dropdown/aanvullende-gegevens-schema";
import { NotificatiesContext } from "adviesbox-shared";
import { mapAllErrorsData } from "../../aanvraag/infra/aanvraag-helper";
import { ProductTypeName } from "../../aanvraag/infra/aanvraag-schema";

export type nieuweAanvraagType = {
  isloading: boolean;
  receivedId: string | null;
  productType: ProductTypeName | null;
  partijCode: string | null;
  closeModal: boolean | null;
};

const setContext = (
  setAanvullendeGegevens: (aanvullendeGegevens: AanvullendeGegevensType) => void,
  aanvullendeGegevens: AanvullendeGegevensType,
  setNieuweAanvraag: (nieuweAanvraag: nieuweAanvraagType | null) => void,
  nieuweAanvraag: nieuweAanvraagType | null
): DossierFoutenContextType => ({
  setAanvullendeGegevens,
  aanvullendeGegevens,
  setNieuweAanvraag,
  nieuweAanvraag
});

const DossierAanvraagFoutenProvider = ({ children }: { children: ReactNode }): ReactElement => {
  const [aanvullendeGegevens, setAanvullendeGegevens] = useState<AanvullendeGegevensType>(
    aanvullendeGegevensSchema.default()
  );
  const [nieuweAanvraag, setNieuweAanvraag] = useState<nieuweAanvraagType | null>(null);
  const { notificaties } = useContext(NotificatiesContext);

  // Als er een notificatie wordt ontvangen voor de aanvraag, dient het scherm uit wachtstand gehaald te worden.
  useEffect(() => {
    if (nieuweAanvraag?.receivedId && notificaties?.some(entry => entry.uniekID === nieuweAanvraag?.receivedId)) {
      const notificatie = notificaties.find(entry => entry.uniekID === nieuweAanvraag?.receivedId);
      if (notificatie?.message) {
        const message = JSON.parse(notificatie.message);
        const mappedAanvullendeGegevens = mapAllErrorsData(message, nieuweAanvraag);
        setAanvullendeGegevens(mappedAanvullendeGegevens);

        /* istanbul ignore next */
        setNieuweAanvraag({
          isloading: false,
          closeModal: nieuweAanvraag?.closeModal === null ? null : true,
          partijCode: nieuweAanvraag?.partijCode ?? null,
          productType: nieuweAanvraag?.productType ?? null,
          receivedId: null
        });
      }
    }
  }, [aanvullendeGegevens, nieuweAanvraag, notificaties, setAanvullendeGegevens]);

  return (
    <DossierAanvraagFoutenContext.Provider
      value={setContext(setAanvullendeGegevens, aanvullendeGegevens, setNieuweAanvraag, nieuweAanvraag)}
    >
      {children}
    </DossierAanvraagFoutenContext.Provider>
  );
};

export default DossierAanvraagFoutenProvider;
