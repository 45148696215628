/* istanbul ignore file */
/* eslint-disable */
import React from "react";
import { Table } from "react-bootstrap";
import { NbiResult } from "../../../.generated/rapportage/rapportagetypes";
import { bedragFormat } from "adviesbox-shared";

interface NBITableProps {
  nbi: NbiResult[];
}

const bedragFormatNull = (bedrag: number | null, decimals: number = 0): string =>
  bedrag !== null ? bedragFormat(bedrag, decimals) : "";

export const TableNettoBesteedbaarInkomen: React.FC<NBITableProps> = ({ nbi }) => {
  if (!nbi || nbi.length === 0) return <></>;
  return (
    <Table responsive striped bordered hover className="mb-2">
      <thead>
        <tr>
          <th>Jaar</th>
          <th>Leeftijd aanvrager</th>
          <th>Leeftijd Partner</th>
          <th>Inkomsten</th>
          <th>Uitgaven</th>
          <th>Netto besteed</th>
          <th>Gewenst netto besteed*</th>
          <th>Tekort/overschot</th>
          <th>Bijzonder</th>
        </tr>
      </thead>
      <tbody>
        {nbi?.map((nbiI, index) => (
          <tr key={index}>
            <td>{nbiI.jaar}</td>
            <td>{nbiI.leeftijdAanvrager}</td>
            <td>{nbiI.leeftijdPartner}</td>
            <td className="text-right">{bedragFormatNull(nbiI.inkomsten)}</td>
            <td className="text-right">{bedragFormatNull(nbiI.uitgaven)}</td>
            <td className="text-right">{bedragFormatNull(nbiI.nettoBesteed)}</td>
            <td className="text-right">{bedragFormatNull(nbiI.gewenstNettoBesteed)}</td>
            <td className="text-right">{bedragFormatNull(nbiI.tekortOverschot)}</td>
            <td>{(nbiI.bijzonderheden || [""]).join(", ")}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

